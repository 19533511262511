import { Injectable } from "@angular/core";
import "rxjs/add/observable/of";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfigService, CommonServices } from "@pulse/common";

@Injectable({
  providedIn: "root",
})
export class AddEditPropertyGroupService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private dateUtilService: CommonServices
  ) {}

  propertyGroupId: number;
  apiUrl = "propertyGroupAPI";
  addGroupURL = "property-group/CreateBuildingGroup";
  updateGroupUrl = "property-group/UpdateBuildingGroup";

  addGroup(obj: {}): Observable<number> {
    let config = {
      headers: {
        timezone: this.dateUtilService.getTimeZones(),
      },
    };
    return this.http.post<number>(
      this.appConfig.getApi(this.apiUrl) + this.addGroupURL,
      obj,
      config
    );
  }

  updateGroup(obj: {}): Observable<number> {
    let config = {
      headers: {
        timezone: this.dateUtilService.getTimeZones(),
      },
    };
    return this.http.post<number>(
      this.appConfig.getApi(this.apiUrl) + this.updateGroupUrl,
      obj,
      config
    );
  }

  getAllProperties(obj: any) {
    return this.http.post<any>(
      this.appConfig.getApi("propertyFilter") + "property-filter/byFilter",
      obj
    );
  }
}
