import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-powerbi-metrics',
  templateUrl: './powerbi-metrics.component.html',
  styleUrls: ['./powerbi-metrics.component.scss']
})
export class PowerbiMetricsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
