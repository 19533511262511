import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  UserService,
  LoaderService,
  AppDataSharingService,
  DataStorageService,
} from "@pulse/common";
import { Router } from "@angular/router";
//import { LoaderService } from './core/loader.service';
import { Store } from "@ngrx/store";
import { IAppState } from "@pulse/common";
import { ToggleFilterPanel } from "@pulse/common";
import { AppConfigService, capabilites } from "@pulse/common";
import { Constatnts } from "../assets/constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { datadogRum } from "@datadog/browser-rum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public isMobile: boolean;
  public screenSize = window.screen.availWidth;
  public isLoaderVisible: boolean = false;
  public isTopSection: boolean = false;
  public isMenuCollapsed: boolean = false;
  public isAgreementsEmpty: boolean = true;
  public hasUserInfo: boolean = false;
  userAgrement: boolean = false;
  public mobileNav: boolean = false;
  csWidgetJsUrl: string;
  readonly INVALID_USER = "Invalid user. Not have Privilages to application";
  toggleFilterPanel: boolean;
  @ViewChild("scrollWrapper") private scrollContainer: ElementRef;
  killSwitches: any;
  hideChatBot: boolean = false;
  agreements: any = [];
  showAddContactModalFlag = false;
  showAddDateModalFlag = false;
  showAddDocModalFlag = false;
  showAddpropertyGroupModalFlag = false;
  isTranslateLoaded: boolean = false;
  enablePropertyFilter: boolean = true;
  propertyFilterCapabilities = [
    capabilites.CONTACTS,
    capabilites.CRITICALDATES,
    capabilites.DASHBOARD,
    capabilites.DOCUMENTS,
    capabilites.BIMETRICS,
    capabilites.BIANALYTICS,
    capabilites.PROPERTYDETILAS,
  ];

  constructor(
    private userService: UserService,
    public router: Router,
    private loaderService: LoaderService,
    private appDataSharingService: AppDataSharingService,
    private renderer: Renderer2,
    private store: Store<IAppState>,
    private configService: AppConfigService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private dataStorageService: DataStorageService
  ) {
    this.renderer.addClass(document.body, "hide-loader");
    this.csWidgetJsUrl = configService.get("cs_widget_js_url");
    this.killSwitches = this.configService.get("killSwitches");
    if (this.killSwitches && this.killSwitches.chatbot) {
      this.hideChatBot = this.killSwitches.chatbot;
    }
    this.initializeDataDog();
  }

  ngOnInit() {
    this.translate.use(this.userService.getLanguage()).subscribe(
      (res) => {
        this.isTranslateLoaded = true;
      },
      (err) => {
        this.translate.setDefaultLang(this.userService.getLanguage());
        this.isTranslateLoaded = true;
      }
    );
    this.screenSize <= 414 ? (this.isMobile = true) : (this.isMobile = false);
    this.validateUser();
    this.validatePropertyFilterNav();
    this.loaderService.isLoaderVisible.subscribe((value) => {
      this.isLoaderVisible = value;
    });
    this.appDataSharingService.isTopSectionVisible.subscribe((value) => {
      this.isTopSection = value;
    });
    this.appDataSharingService.isMenuCollapsed.subscribe((value) => {
      this.isMenuCollapsed = value;
      this.cdRef.detectChanges();
    });
    this.appDataSharingService.isUserInfoAvailable.subscribe((value) => {
      if (!this.hasUserInfo && value) {
        this.hasUserInfo = value;
      }
    });
    this.appDataSharingService.resetScrollToTop.subscribe((value) => {
      this.scrollContainer.nativeElement.scrollTop = 0;
    });
    this.appDataSharingService.isAgreementsEmpty.subscribe((value) => {
      if (value) {
        this.isAgreementsEmpty = value;
      }
    });

    this.appDataSharingService.openNewContactDialog.subscribe((value) => {
      if (value) {
        this.toggleAddContactModal();
      }
    });

    this.appDataSharingService.openNewDocumentDialog.subscribe((value) => {
      if (value) {
        this.toggleAddDocModal();
      }
    });

    this.appDataSharingService.openNewCriticalDateDialog.subscribe((value) => {
      if (value) {
        this.toggleAddDateModal();
      }
    });

    this.appDataSharingService.openNewGroupDialog.subscribe((value) => {
      if (value) {
        this.toggleAddPropertyGroupModal();
      }
    });

    this.appDataSharingService.navigateToPage.subscribe((value) => {
      if (value) {
        this.router.navigate(Array.isArray(value) ? value : [value]);
      }
    });

    // subs for toggling the panel
    this.store
      .select((state) => state.overview)
      .subscribe(({ isFilterPanelOpened }) => {
        this.toggleFilterPanel = isFilterPanelOpened;
      });
  }

  initializeDataDog() {
    const datadogConf = this.configService.get("datadog");
    if (datadogConf) {
      datadogRum.init({
        ...datadogConf,
        site: "datadoghq.com",
        service: "global-pulse",
        env: this.configService.environment,
        version: "1.0.0",
        sampleRate: 100,
        trackInteractions: true,
      });

      datadogRum.addRumGlobalContext("cbre_details", {
        tower: "global_advisory",
        lob: "propertymanagement",
        application: "global-pulse",
        apm_id: 660788,
      });

      datadogRum.setUser({
        name: this.userService.getUserName(),
        email: this.userService.getUserEmail(),
      });
    }
  }

  validatePropertyFilterNav() {
    let _flag = this.userService.userInfo.features.some((i) =>
      this.propertyFilterCapabilities.includes(i.capability)
    );

    this.enablePropertyFilter = _flag;
  }

  toggleAddContactModal() {
    this.showAddContactModalFlag = !this.showAddContactModalFlag;
    // this.templateName = 'createContact';
  }
  toggleAddDateModal() {
    this.showAddDateModalFlag = !this.showAddDateModalFlag;
  }
  toggleAddPropertyGroupModal() {
    this.showAddpropertyGroupModalFlag = !this.showAddpropertyGroupModalFlag;
  }
  toggleAddDocModal() {
    this.showAddDocModalFlag = !this.showAddDocModalFlag;
  }

  validateUser() {
    if (
      !this.userService.userInfo ||
      this.userService.userInfo.message === this.INVALID_USER ||
      !this.userService.userInfo.accessToken
    ) {
      this.router.navigate(["unauthorized"]);
    } else if (this.userService.userInfo.agreements) {
      this.handleAgreements();
    } else {
      const url = this.appDataSharingService.getRedirectUrl();
      if (url) {
        this.router.navigate([url]);
        this.appDataSharingService.setRedirectUrl("");
      } else {
        const defaultLanding =
          this.dataStorageService.readFromSessionStorage("defaultLandingPage");
        if (defaultLanding) {
          this.router.navigate([defaultLanding]);
        }
      }
    }
  }

  handleAgreements() {
    const agreements = this.userService.userInfo.agreements;
    const landingPageAgreements = agreements.filter(
      (agreement) => agreement.page_name === "landing"
    );
    if (landingPageAgreements.length > 0) {
      this.isAgreementsEmpty = false;
      this.router.navigate([Constatnts.AGREEMENT_ROUTE]);
    }
  }

  unmaskPage() {
    this.toggleFilterPanel = false;
    this.store.dispatch(new ToggleFilterPanel(false));
  }

  mobileHamburger() {
    this.mobileNav = true;
    this.appDataSharingService.setSideNavCollapseSubject({ menu: true });
  }

  mobNavClose(data) {
    if (!data) {
      this.mobileNav = false;
    }
  }

  topSectionClick() {
    this.appDataSharingService.showTopSection(false);
    this.router.navigate(["/poc/poc-card"]);
  }
}
