import { Component, OnInit } from '@angular/core';
import { AppDataSharingService } from '@pulse/common';

@Component({
  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.scss']
})
export class TableauComponent implements OnInit {
  tableauURL = "https://emeainsightreporting.cbre.eu/javascripts/api/viz_v1.js";
  constructor(private appDataSharingService: AppDataSharingService) { }

  ngOnInit(): void {
    this.appDataSharingService.setHideLoader(true);
  }

}
