import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@pulse/common';
import { UserIdleService } from 'angular-user-idle';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(private userService: UserService, private router: Router, private userIdle: UserIdleService) { }

  ngOnInit() {
    if (this.userService.userInfo && this.userService.userInfo.accessToken) {
      this.router.navigate(['properties/overview']);
    } else {
      this.userIdle.stopTimer();
    }
  }
}
