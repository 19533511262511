import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FeatureFlagGuardService } from "@pulse/common";
import { features, parentFeature, capabilites } from "@pulse/common";
import { UnauthorizedComponent } from "./shared/unauthorized/unauthorized.component";
import { EditDistributionGroupComponent, DistributionGroupAuditTrailComponent } from "@pulse/contacts";
import { QuiksightComponent } from "./quiksight/quiksight.component";
import { TableauComponent } from './tableau/tableau/tableau.component';
import { ChatbotComponent } from "./chatbot/chatbot.component";

const routes: Routes = [
  {
    path: "properties",
    loadChildren: "./properties/properties.module#PropertiesModule",
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: features.PROPSOVERVIEW,
    },
  },
  {
    path: "search-results",
    loadChildren: () =>
      import("./search-results/search-results.module").then(
        (m) => m.SearchResultsTestModule
      ),
    // "./search-results/search-results.module/#SearchResultsTestModule",
    // canActivate: [FeatureFlagGuardService],
    // data: {
    //   featureFlag: capabilites.DASHBOARD,
    //   subFeatureFlag: features.PROPSOVERVIEW,
    // },
  },
  // {
  //   path: "search-results",

  //   loadChildren:
  //     "./search-results-overview/search-results.module#SearchResultsModule",
  //   // "./search-results-overview/search-results.module#SearchResultsModule",
  //   canActivate: [FeatureFlagGuardService],
  //   // data: {
  //   //   featureFlag: capabilites.DASHBOARD,
  //   //   subFeatureFlag: features.PROPSOVERVIEW,
  //   // },
  // },
  {
    path: "analytics1",
    loadChildren: "./bi-analytics/analytics-bi.module#BIAnalyticsModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_ANALYTICS },
  },
  {
    path: "analytics",
    loadChildren:
      "./analytics-mockdata/analytics-mockdata.module#AnalyticsMockModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_ANALYTICS },
  },
  {
    path: "eom",
    loadChildren: "./eom/eom.module#EomModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_ANALYTICS },
  },
  {
    path: "eom-market",
    loadChildren: "./eom-market/eom-market.module#EomMarketModule2",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_ANALYTICS },
  },
  {
    path: "stacking-plan",
    loadChildren: "./stacking-plan/stacking-plan.module#StackingPlanModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_ANALYTICS },
  },
  {
    path: "poc",
    loadChildren: "./poc-list/poc-list.module#PocListUIModule",
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTYCONTACTS,
    },
  },
  {
    //path: 'distribution-group/edit-dg/:dgid',
    path: "propertydetail/:id/contacts/edit-dg/:dgid",
    component: EditDistributionGroupComponent,
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTYCONTACTS,
    },
  },
  {
    //path: 'distribution-group/edit-dg/:dgid',
    path: "contacts/communication/distribution-group/edit-dg/:dgid",
    component: EditDistributionGroupComponent,
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTYCONTACTS,
    },
  },
  {
    //path: 'distribution-group/edit-dg/:dgid',
    path: "contacts/communication/distribution-group/audittrail/:dgid",
    component: DistributionGroupAuditTrailComponent,
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTYCONTACTS,
    },
  },
  {
    path: "mail",
    loadChildren: "./mail-module/mail-module.module#ContactsMailModuleModule",
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTYCONTACTS,
    },
  },
  // {
  //   path: "analytics",
  //   loadChildren: "./bi-analytics/analytics-bi.module#BIAnalyticsModule",
  //   canActivate: [FeatureFlagGuardService],
  //   data: { featureFlag: parentFeature.BI_ANALYTICS },
  // },
  {
    path: "settings",
    loadChildren: "./settings/settings.module#SettingsModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.SETTINGS },
  },
  {
    path: "metrics",
    loadChildren: "./metrics/metrics-bi.module#PulseUIMetricsModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_COMPLIANCE },
  },
  {
    path: "Forms",
    loadChildren: "./utilities/pulse-utilities.module#FormUtilitiesModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.BI_COMPLIANCE },
  },
  // {
  //   path: 'Forms',
  //   loadChildren: './utilities/pulse-utilities.module#FormUtilitiesModule',
  //   canActivate: [FeatureFlagGuardService],
  //   data: { featureFlag: capabilites.WOKRFLOW },
  // },
  {
    path: "propertydetail/:id",
    loadChildren: "./property/property.module#PropertyModule",
    canActivate: [FeatureFlagGuardService],
    data: {
      featureFlag: capabilites.DASHBOARD,
      subFeatureFlag: parentFeature.PROPERTY,
    },
  },

  // {
  //   path: 'propertydetail/:id/contacts/communication',
  //   loadChildren: () => import('./contacts/communication.module').then(m => m.AppCommunicationModule),
  //   canActivate: [FeatureFlagGuardService],
  //   data: { featureFlag: capabilites.DASHBOARD, subFeatureFlag: parentFeature.PROPERTY }
  // },
  // {
  //   path: "search-results-overview",
  //   component: SearchResultsOverviewComponent,
  //   canActivate: [FeatureFlagGuardService],
  //   data: {
  //     featureFlag: capabilites.DASHBOARD,
  //     subFeatureFlag: parentFeature.PROPERTY,
  //   },
  // },
  // {
  //   path: "search-results-properties",
  //   component: SpotlightSearchComponent,
  //   canActivate: [FeatureFlagGuardService],
  //   data: {
  //     featureFlag: capabilites.DASHBOARD,
  //     subFeatureFlag: parentFeature.PROPERTY,
  //   },
  // },
  // {
  //   path: "search-results-tenants",
  //   component: TenantsSearchResultsComponent,
  //   canActivate: [FeatureFlagGuardService],
  //   data: {
  //     featureFlag: capabilites.DASHBOARD,
  //     subFeatureFlag: parentFeature.PROPERTY,
  //   },
  // },
  {
    path: "poc/chatbot",
    component: ChatbotComponent,
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.ADMIN },
  },
  {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.ADMIN },
  },
  {
    path: "admin/pc/send-notification",
    loadChildren: "./pc-admin/pc-admin.module#PCAdminModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.ADMIN },
  },
  {
    path: "admin/templates-mgmt",
    loadChildren:
      "./admin-templates/admin-templates.module#AdminTemplatesModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.ADMIN },
  },
  {
    path: "wiki",
    loadChildren: "./wiki/wiki.module#WikiModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.ADMIN },
  },
  {
    path: "wiki",
    loadChildren: "./wiki/wiki.module#WikiModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.PROPERTYGROUP },
  },
  {
    path: "tenant-tracker",
    loadChildren: "./tenant-tracker/tenant-tracker.module#TenantTrackerModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.CBRE_METRICS },
  },
  {
    //path: 'distribution-group/edit-dg/:dgid',
    path: "tableau/reports",
    component: TableauComponent,
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.CBRE_METRICS },
  },
  {
    path: "powerbi",
    loadChildren: "./powerbi/powerbi.module#PowerBiModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: parentFeature.TENANTTRACKER },
  },
  // {
  //   path: 'dataroom',
  //   loadChildren: './dataroom/dataroom.module#DataRoomModule',
  //   canActivate: [FeatureFlagGuardService],
  //   data: { featureFlag: capabilites.DATAROOM }
  // },
  { path: "unauthorized", component: UnauthorizedComponent },

  {
    path: "",
    redirectTo: "/properties/overview",
    pathMatch: "full",
  },
  {
    path: "interstitial",
    loadChildren: "./interstitial/interstitial.module#InterstitialModule",
  },
  {
    path: "my-profile",
    loadChildren: "./profile/profile.module#ProfileModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.PROPERTYGROUP },
  },
  {
    path: "document",
    loadChildren: "./documents/documents.module#DocumentsModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.PROPERTYGROUP },
  },
  {
    path: "tenantdetail/:tenantId",
    loadChildren: "./tenant-detail/tenant-detail.module#TenantDashboardModule",
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.PROPERTYGROUP },
  },
  {
    path: "quicksight/dashboard",
    component: QuiksightComponent,
    canActivate: [FeatureFlagGuardService],
    data: { featureFlag: capabilites.PROPERTYGROUP },
  },
  { path: "**", redirectTo: "/properties/overview" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
