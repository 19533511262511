import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PowerBiRoutingModule } from "./powerbi-routing.module";
import { PowerbiMetricsComponent } from "./powerbi-metrics/powerbi-metrics.component";
import { PowerbiComponent } from "./powerbi/powerbi.component";
import { NgxPowerBiModule } from "ngx-powerbi";

import { PulseDialogModule, PulseFormModule } from "@pulse/ui-library";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [PowerbiMetricsComponent, PowerbiComponent],
  imports: [
    CommonModule,
    PowerBiRoutingModule,
    NgxPowerBiModule,
    PulseDialogModule,
    TranslateModule,
    PulseFormModule,
    FormsModule,
  ],
})
export class PowerBiModule {}
