import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-donut-graph',
  templateUrl: './donut-graph.component.html',
  styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {
  @Input()
  percentage: number;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  centerX: number;

  @Input()
  centerY: number;

  @Input()
  radius: number;

  @Input()
  strokeWidth: number;

  @Input()
  strokeColor: string;

  @Input()
  strokeBackgroundColor: string;

  constructor() {}

  ngOnInit() {}
}
