import { Component, OnInit } from '@angular/core';
import { WindowRef } from '@pulse/common';

@Component({
  selector: 'app-documents-presentation',
  templateUrl: './documents-presentation.component.html',
  styleUrls: ['./documents-presentation.component.scss']
})
export class DocumentsPresentationComponent implements OnInit {
  constructor(private windowRef: WindowRef) { }

  ngOnInit() { }
}
