import { Injectable } from '@angular/core';
import { FeatureFlagService, MenuConfiguration } from "@pulse/common";
import { MenuItems } from './interfaces/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private featureFlagService: FeatureFlagService) { }

  showMenuItem(featureName: string, subFeature: string): boolean {
    if (subFeature) {
      return this.featureFlagService.subFeatureOn(featureName, subFeature);
    }
    return this.featureFlagService.featureOn(featureName);
  }

  getSubMenuItems(featureName): MenuItems[] {
    const menuConfig = MenuConfiguration;
    let subMenuItems: MenuItems[] = [];
    menuConfig.forEach((menuItem: MenuItems) => {
      if (menuItem.featureName === featureName) {
        subMenuItems = menuItem.subMenu;
      }
    });
    return subMenuItems;
  }
}
