import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { AppDataSharingService, AppNotificationService, debounce } from "@pulse/common";
import { Property } from "../../core/interfaces/property.model";
import { PropertyGroup } from "../../core/interfaces/propertyGroup.model";
import { PropertyGroupMappingResult } from "../../core/interfaces/propertyGroupMappingResult.model";
import { getPropertiesDropDown } from "@pulse/common";
import { IAppState } from "@pulse/common";
import { PropertyGroupDocumentsService } from "../property-group-documents.service";
import {
  FormLables,
  Messages,
  PropertyGroupFormFields,
} from "./add-edit-property-group.configuration";
import { AddEditPropertyGroupService } from "./add-edit-property-group.service";
import { UserService } from "@pulse/common";
import { UserInfo } from "@pulse/common";
import { CommonService } from "../../shared/services/common.service";
import { ValidatorUtilService } from "../../shared/services/validator-util.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-edit-property-group",
  templateUrl: "./add-edit-property-group.component.html",
  styleUrls: ["./add-edit-property-group.component.scss"],
})
export class AddEditPropertyGroupComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  propertyId;
  title: string;
  formFields = PropertyGroupFormFields.slice();
  lables = FormLables;
  propertGroupForm: FormGroup;
  primaryPropetiesList: Property[] = [];
  secondryPropetiesList: Property[] = [];
  allProperties: Property[];
  private ngUnsubscribe: Subject<void> = new Subject();
  noItemsMessage: string = this.translateService.instant('NO_ITEMS_MESSAGE');
  @Input() isEditGroup: boolean;
  @Output() hideDialog = new EventEmitter<string>();
  @Input() propertyGroupItem: PropertyGroupMappingResult;
  constructor(
    private appDataSharingService: AppDataSharingService,
    private store: Store<IAppState>,
    private addEditPropertyGroupService: AddEditPropertyGroupService,
    private notificationService: AppNotificationService,
    private propertyGroupDocumentService: PropertyGroupDocumentsService,
    private fb: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private validatorUtil: ValidatorUtilService
  ) {
    this.propertyId = this.appDataSharingService.getPropertyId();
  }

  ngOnInit() {
    this.title = this.isEditGroup ? this.translateService.instant(FormLables.editText) : this.translateService.instant(FormLables.addText);
    this.createForm();
    this.setDefaultvalues();
    // this.store
    //   .select(getPropertiesDropDown)
    //   .pipe(
    //     (propObservable) => combineLatest(propObservable),
    //     takeUntil(this.ngUnsubscribe)
    //   )
    //   .subscribe(([properties]) => {
    //     this.allProperties = properties.filter(
    //       (property) => !property.buildingGroupId || (this.propertyGroupItem && this.propertyGroupItem.id === property.buildingGroupId));
    //     this.setDefaultvalues();
    //   });
  }

  setDefaultvalues() {
    // if (this.allProperties) {
    //   this.primaryPropetiesList = this.allProperties.slice();

    //   this.secondryPropetiesList = this.allProperties.slice();
    // }
    if (this.isEditGroup && this.propertyGroupItem) {
      this.updateFormData();
      this.secondryPropetiesList = this.propertyGroupItem.secondaryProperties.slice();
    }
  }

  /**
   * prefilling form data in form is opened in edited mode.
   */
  updateFormData() {
    this.propertGroupForm.setValue({
      groupName: this.propertyGroupItem.name,
      selectedPrimaryProperty: [this.propertyGroupItem.primaryProperty],
      selectedSecondryProperties: this.propertyGroupItem.secondaryProperties,
    });
    this.propertGroupForm.controls.selectedPrimaryProperty.disable();
  }
  /**
   * event for On Property change.
   * @param selectedItems : Array of selcted Items
   * @param isPrimaryProperty : Is Primary property dropdown is selected
   */
  onPropertyChange(selectedItems: Property[], isPrimaryProperty: boolean) {
    if (isPrimaryProperty) {
      this.secondryPropetiesList = [];
      this.propertGroupForm.patchValue({ selectedSecondryProperties: null });
      if (selectedItems.length)
        this.propertGroupForm.controls.selectedSecondryProperties.enable();
      else
        this.propertGroupForm.controls.selectedSecondryProperties.disable();
    }

  }

  @debounce(500)
  onPrimaryPropertyFilter(val) {
    const value = val.trim();
    if (value && value.length >= 3) {
      let formData: any = { "searchString": value };
      this.getPrimerytPropertiesByFilter(formData)
    } else {
      this.noItemsMessage = this.translateService.instant('NO_ITEMS_MESSAGE');
    }
  }

  getPrimerytPropertiesByFilter(formData) {
    this.addEditPropertyGroupService.getAllProperties(formData).subscribe(
      props => {
        if (props && props.content && props.content.length) {
          this.primaryPropetiesList = props.content.filter(prop => !prop.buildingGroupId);
        } else {
          this.primaryPropetiesList = [];
          this.noItemsMessage = this.translateService.instant('NO_ITEMS_FOUND');
        }
      },
      error => {
        this.filterFetchErrorCalback(formData);
      }
    );
  }

  @debounce(500)
  onSecondaryPropertyFilter(val) {
    const value = val.trim();
    const property = this.isEditGroup ? this.propertyGroupItem.primaryProperty : this.propertGroupForm.value.selectedPrimaryProperty[0];
    let formData: any = { "searchString": value || '', clientId: [Number(property.clientId)] };
    this.getSecondaryPropertiesByFilter(formData, property)
  }

  getSecondaryPropertiesByFilter(formData, property) {
    this.addEditPropertyGroupService.getAllProperties(formData).subscribe(
      props => {
        if (props && props.content && props.content.length) {
          const properties = props.content.filter(prop => !prop.buildingGroupId && prop.id !== property.id);
          if (this.isEditGroup) {
            this.secondryPropetiesList = properties.concat(this.propertyGroupItem.secondaryProperties.slice())
          } else {
            this.secondryPropetiesList = properties;
          }
        } else {
          this.secondryPropetiesList = this.isEditGroup ? this.propertyGroupItem.secondaryProperties.slice() : [];
          this.noItemsMessage = this.translateService.instant('NO_ITEMS_FOUND');
        }
      },
      error => {
        this.filterFetchErrorCalback(formData);
      }
    );
  }

  filterFetchErrorCalback(formData) {
    this.noItemsMessage = "";
    this.notificationService.showErrorNotication(
      this.translateService.instant('ERROR_NOTIFICATION') + `${formData.searchString}` + this.translateService.instant('TRY_AGAIN')
    );
  }

  handleError(e: any, msg: string) {
    console.log(e);
    return msg;
  }

  createForm() {
    this.propertGroupForm = this.fb.group({
      groupName: [
        null,
        [this.validatorUtil.noWhitespaceValidator(), Validators.required],
      ],
      selectedPrimaryProperty: [
        null,
        [
          Validators.required,
          this.PropertyNameExists.bind(this),
          this.PropertyForSameClients.bind(this),
        ],
      ],
      selectedSecondryProperties: [
        { value: null, disabled: !this.isEditGroup },
        [
          Validators.required,
          this.PropertyNameExists.bind(this),
          this.PropertyForSameClients.bind(this),
        ],
      ],
    });
  }

  PropertyNameExists(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let isPropertyExists = false;
    if (control && control.value && this) {
      control.value.forEach((element) => {
        if (!this.isEditGroup) {
          if (
            element.buildingGroupId !== undefined &&
            element.buildingGroupId > 0
          ) {
            return (isPropertyExists = true);
          }
        } else {
          const existingItem = this.propertyGroupItem.secondaryProperties.filter(
            (xx) => xx.id === element.id
          );
          if (!(existingItem && existingItem.length > 0)) {
            if (
              element.buildingGroupId !== undefined &&
              element.buildingGroupId > 0
            ) {
              return (isPropertyExists = true);
            }
          }
        }
      });
    }
    if (isPropertyExists) {
      return { propertyExistsInGroup: isPropertyExists };
    }
    return null;
  }

  PropertyForSameClients(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let isDifferentClient = false;
    if (control && control.value) {
      if (
        control.parent.controls["selectedPrimaryProperty"].value !== null &&
        control.parent.controls["selectedPrimaryProperty"].value !==
        undefined &&
        control.parent.controls["selectedPrimaryProperty"].value.length > 0
      ) {
        let prevClientID =
          control.parent.controls["selectedPrimaryProperty"].value[0].clientId; // Primary property client id
        control.value.forEach((element) => {
          const currentClientID = element.clientId;
          if (prevClientID != currentClientID) {
            return (isDifferentClient = true);
          } else {
            prevClientID = currentClientID;
          }
        });
        if (isDifferentClient) {
          return { shouldHaveSameClient: isDifferentClient };
        }
      }
    }
    return null;
  }
  close() {
    this.hideDialog.next("close");
  }

  validateForm(): void {
    if (this.propertGroupForm.valid) {
      this.onSubmit();
    } else {
      this.validateAllFormFields();
    }
  }

  generatePostData(obj: {}) {
    const formData = JSON.parse(JSON.stringify(obj));
    const group = {} as PropertyGroup;

    group.groupID = this.propertyGroupItem ? this.propertyGroupItem.id : 0;

    if (this.isEditGroup) {
      group.primaryPropertyID = this.propertyGroupItem.primaryProperty.id;
      group.clientID = this.propertyGroupItem.primaryProperty.clientId;
    } else {
      group.primaryPropertyID = formData.selectedPrimaryProperty[0].id;
      group.clientID = formData.selectedPrimaryProperty[0].clientId;
    }
    group.groupName = formData.groupName;
    group.secondaryPropertyIDs = formData.selectedSecondryProperties ? formData.selectedSecondryProperties.map(
      (property) => property.id
    ) : [];
    return group;
  }

  onSubmit() {
    const formData = { ...this.propertGroupForm.value };
    const postData = this.generatePostData(formData);
    const postDataId = {} as UserInfo;
    postDataId.userID = this.userService.getUserId();
    // this.sub.add(
    //   this.commonService.clearCache(postDataId).subscribe((result) => {})
    // );
    this.sub.add(
      this.addEditPropertyGroupService[
        this.isEditGroup ? "updateGroup" : "addGroup"
      ](postData).subscribe(
        (groupId) => {
          postData.groupID = groupId;
          this.onSaveSuccess(postData);
        },
        (error) => {
          this.onSaveError.bind(this, error)();
        }
      )
    );
  }

  onSaveSuccess(groupItem?: PropertyGroup) {
    if (groupItem) {
      this.notificationService.showSuccessNotication(
        this.isEditGroup ? this.translateService.instant(Messages.updateSucces) : this.translateService.instant(Messages.saveSuccess)
      );
      const currentFilterValues = this.appDataSharingService.getPropertyFilterValues();
      if (Object.keys(currentFilterValues).length === 0) {
        this.appDataSharingService.addedGroupItem.next(groupItem);
      }
      this.propertyGroupDocumentService.updateCategories(
        this.extractProperties(),
        this.appDataSharingService.getOtdsTicket(),
        groupItem.groupID,
        groupItem.groupName
      );
    }
    this.close();
  }

  extractProperties() {
    const formData = { ...this.propertGroupForm.value };
    let properties = [];
    if (this.isEditGroup && this.propertyGroupItem) {
      properties.push(this.propertyGroupItem.primaryProperty);
    } else {
      if (formData.selectedPrimaryProperty) {
        formData.selectedPrimaryProperty[0].isPrimaryBuilding = "y";
        properties.push(formData.selectedPrimaryProperty[0]);
      }
    }
    const secondaryProperties = formData.selectedSecondryProperties ? formData.selectedSecondryProperties.map(
      (property) => {
        property.isPrimaryBuilding = "n";
        return property;
      }
    ) : [];
    properties = properties.concat(secondaryProperties);
    return properties;
  }

  onSaveError(e) {
    if (e.status === 200) {
      this.onSaveSuccess();
    } else if (
      e.error &&
      e.error.toLowerCase().includes("is already being used")
    ) {
      this.notificationService.showErrorNotication(
        this.propertGroupForm.value.groupName + this.translateService.instant(Messages.duplicateGroupError)
      );
    } else {
      this.notificationService.showErrorNotication(this.translateService.instant(Messages.saveError));
    }
    this.handleError(e, this.translateService.instant('CREATE_GROUP_FAILED'));
  }

  validateAllFormFields(): void {
    Object.keys(this.propertGroupForm.controls).forEach((field) => {
      const control = this.propertGroupForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
