import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "@pulse/common";

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  getPropertiesURI = "property-filter/properties";
  chatInitCreationURI = "search/uploadToSmartSearch";
  chatInitInteractionURI = "search/docInteract";
  chatInitDeletionURI = "search/deleteSession";
  smartSearchURI="search/chatInteract"
  constructor(private http: HttpClient, private config: AppConfigService) {}

  getPropertiesData() {
    let postBody = {
      pageNumber: 1,
      pageSize: 20,
      includeInActive: false,
    };
    let postURL =
      this.config.getApi("propertyFilter") + this.getPropertiesURI;
    return this.http.post(postURL, postBody);
  }
  createGPTAccess(postBody) {

    let postURL =
      this.config.getApi("globalSearchAPI") + this.chatInitCreationURI;
    return this.http.post(postURL, postBody);
  }
  chatBotInteraction(postBody) {

    let postURL =
      this.config.getApi("globalSearchAPI") + this.chatInitInteractionURI;
    return this.http.post(postURL, postBody);
  }
  chatBotAccessDelete(postBody,sessionId) {

    let postURL =
      this.config.getApi("globalSearchAPI") + this.chatInitDeletionURI+"/"+sessionId;
    return this.http.post(postURL, postBody);
  }
  chatInteractSmartSearch(postBody) {

    let postURL =
      this.config.getApi("globalSearchAPI") + this.smartSearchURI
    return this.http.post(postURL, postBody);
  }
}
