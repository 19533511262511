import { Component, OnInit, Input } from "@angular/core";
import { DateUtilService } from "../../services/date-util.service";
import { MobileService } from "src/app/core/mobile.service";
import { PropertyGroupMappingResult } from "../../../core/interfaces/propertyGroupMappingResult.model";
import { ImageConfig } from "../../../property/property-image.configuration";
import {
  AppDataSharingService,
  AppConfigService,
  UserService,
} from "@pulse/common";
@Component({
  selector: "app-property-card",
  templateUrl: "./property-card.component.html",
  styleUrls: ["./property-card.component.scss"],
})
export class PropertyCardComponent implements OnInit {
  @Input()
  group: PropertyGroupMappingResult;
  @Input()
  showUploadImageIcon: boolean;
  isUploadModalVisible: boolean = false;
  groupName: string;
  isFilteredByGroup = false;
  imageLoadFailed = false;
  uomCode: string = "SQ FT";
  config: any = JSON.parse(JSON.stringify(ImageConfig));
  constructor(
    private dateUtil: DateUtilService,
    public mbl: MobileService,
    private appDataSharingService: AppDataSharingService,
    private configService: AppConfigService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.config.serviceUrl =
      this.configService.getApi("globalPropertyAPI") + this.config.serviceUrl;
    this.uomCode = this.userService.getUOM();
  }

  getRouteUrl(id): string {
    return "/propertydetail/" + id + "/critical-dates";
  }

  onViewGroupClick(groupId: number) {
    this.appDataSharingService.viewGroupTriggered.next(groupId);
  }

  setDefaultImage() {
    this.imageLoadFailed = true;
  }

  showUploadModal() {
    this.isUploadModalVisible = true;
  }

  hideUploadModal() {
    this.isUploadModalVisible = false;
  }
}
