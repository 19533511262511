import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AppDataSharingService } from '@pulse/common';

//import { ConfirmDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
export interface ComponentCanDeactivate {
  textchangetrack: any;
  canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
}

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class PendingChangesFlagGuardService
  implements CanDeactivate<ComponentCanDeactivate> {
  //modalRef: BsModalRef;
  constructor(private dataSharingService: AppDataSharingService) {}
  public value: Observable<boolean>;

  shouldAllowNavigation(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.dataSharingService.allowNavigation.subscribe(navigate => {
        if (navigate === 'navigate') {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
    return promise;
  }
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (component.textchangetrack == 1) {
      component.canDeactivate();
      return this.shouldAllowNavigation();
    } else {
      return true;
    }
  }
}
