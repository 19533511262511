export interface FormItem {
  label: string;
  formControlName: string;
  type: string;
  validations?: object[];
  options?: any;
  value?: string;
  key?: string;
  changeHandler?: string;
  formGroup?: string;
  className?: string;
  filterChange?: string;
  parentClassName?: string;
  onChange?: string;
  disabled?: boolean;
  hidden?: boolean;
  isPrimaryProperty?: boolean;
  isRequired?: boolean;
}

export interface FormRow {
  formGroup: string;
  columns: FormItem[];
}

export const PropertyGroupFormFields: FormRow[] = [
  {
    formGroup: "",
    columns: [
      {
        label: "GROUP_NAME",
        isRequired: true,
        type: "input",
        options: "clients",
        parentClassName: "quarter3-field",
        value: "id",
        key: "organization",
        formControlName: "groupName",
        validations: [
          { type: "whitespace", message: "ENTER_GROUP_NAME" },
        ],
      },
    ],
  },
  {
    formGroup: "",
    columns: [
      {
        label: "PRIMARY_PROPERTY",
        isRequired: true,
        type: "custom",
        formControlName: "selectedPrimaryProperty",
        options: "primaryPropetiesList",
        value: "id",
        key: "name",
        className: "property-dropdown",
        onChange: "onPropertyChange",
        parentClassName: "quarter3-field",
        filterChange: "onPrimaryPropertyFilter",
        isPrimaryProperty: true,
        validations: [
          { type: "required", message: "SELECT_ATLEAST_ONE_PROPERTY" },
          {
            type: "shouldHaveSameClient",
            message: "PROPERTY_SELECTED_NOT_HAVE_SAME_CLIENT",
          },
          {
            type: "propertyExistsInGroup",
            message: "PROPERTY_SELECTED_ALREADY_EXIST",
          },
        ],
      },
    ],
  },
  {
    formGroup: "",
    columns: [
      {
        label: "SECONDARY_PROPERTIES",
        isRequired: true,
        type: "custom",
        formControlName: "selectedSecondryProperties",
        options: "secondryPropetiesList",
        value: "id",
        key: "name",
        className: "property-dropdown",
        onChange: "onPropertyChange",
        filterChange: "onSecondaryPropertyFilter",
        parentClassName: "block-field",
        validations: [
          { type: "required", message: "SELECT_ATLEAST_ONE_PROPERTY" },
          {
            type: "shouldHaveSameClient",
            message: "PROPERTY_SELECTED_NOT_HAVE_SAME_CLIENT",
          },
          {
            type: "propertyExistsInGroup",
            message: "PROPERTY_SELECTED_ALREADY_EXIST",
          },
        ],
      },
    ],
  },
];

export interface ApiInfo {
  method: string;
  helper: string;
  errorMsg: string;
  field: string;
  action?: string;
  param?: string;
}

export const PageLoadApis: ApiInfo[] = [
  {
    method: "getProperties",
    helper: "prepareProperties",
    errorMsg: "error",
    field: "allProperties",
    action: "StoreProperties",
  },
];

// {
//   method: 'getAllClients',
//   helper: 'prepareClients',
//   errorMsg: 'error',
//   field: 'clients',
//   action: 'StoreClients'
// },

export const FormLables: any = {
  addText: "CREATE_PROPERTY_GROUP",
  editText: "EDIT_PROPERTY_GROUP",
  cancelText: "BUTTON_CANCEL",
  updateText: "UPDATE_GROUP",
  submitText: "CREATE_GROUP",
};

export const Messages: any = {
  saveSuccess: "PROPERTY_SAVED_SUCCESS_MSG",
  updateSucces: "PROPERTY_UPDATED_MSG",
  saveError: "PROPERTY_ERROR_MSG",
  duplicateGroupError: "DUPLICATE_ERR_MSG",
};
