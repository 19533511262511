import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  public screenSize = window.screen.availWidth;
  public isMobile: boolean;

  constructor() {
    this.screenSize <= 768 ? this.isMobile = true : this.isMobile = false;
  }
}
