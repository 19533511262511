import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'Delete',
    'ArrowLeft',
    'ArrowRight'
  ];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const keyCode = e.which || e.keyCode || 0;
    if (
      this.specialKeys.indexOf(e.key) !== -1 ||
      (keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (keyCode === 86 && e.ctrlKey === true) || // Allow : Ctrl+V
      (keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (keyCode === 90 && e.ctrlKey === true) // Allow: Ctrl+Z
    ) {
      return;
    }

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }
  @HostListener('paste', ['$event'])
  onPaste(e: KeyboardEvent) {
    const pastedData = e['clipboardData'].getData('Text');
    if (isNaN(pastedData)) {
      e.preventDefault();
    }
  }
}
