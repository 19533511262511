export const Constatnts = {
  DATE_COMPLETED: "COMPLETED",
  DATE_OPEN: "OPEN",
  DATE_DUE: "PAST DUE",
  DATE_SYSTEM: "System Generated",
  DATE_DOC: "Doc Centre",
  DATE_MANUAL: "Manual",
  CONTACT_SYSTEM: "system",
  CONTACT_MANUAL: "manual",
  PAGESIZE: 100,
  OVERVIEWPAGESIZE: 20,
  CSROLE_OWNERS: "Owners",
  CSROLE_POWERUSER: "PowerUsers",
  CSROLE_CONTRIBUTER: "Contributors",
  CSROLE_CLIENT: "Clients",
  CSROLE_CONSUMER: "Consumers",
  CSROLE_SUPPORT_ADMIN: "support-admins",
  OTDS_INTERVAL_TIME: 1740000,
  TOKEN_TTL: 600000,
  TWO_MINS: 60000,
  ERROR_MSG: "ERROR_MSG",
  EXPORT_OPTIONS: ["pdf", "jpg", "png"], //['pdf', 'jpg', 'png']
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  AGREEMENT_ROUTE: "interstitial/userAgreement",
  REFRESH_INTERVAL: 24 * 60 * 60 * 1000 - 10 * 60 * 1000,
  DATE_FORMATS: {
    US: "MM/dd/yyyy",
    EXT: "MM/dd/yyyy",
    EMEA: "dd/MM/yyyy",
    AP: "yyyy/dd/MM"
  },
  MAXAGE: "3600",
  SESSION_MINS: 58,
  SESSION_KEY: "PULSE-SESSION-ID",
  SESSION_EXPIRE: "PULSE-SESSION-EXPIRE",
  VALUMINOUS_ROLES: [2, 23],
  SCALING_QUERY: "?target=admin",
  SESSION_IDLE_TIMEOUT: 300,
  SESSION_IDLE_TIME: 3300,
  ERROR_MSG_USER_OT: "ERROR_MSG_USER_OT",
  CDSTATUSMAP: {
    '2': 'IN PROGRESS',
    '3': 'PENDING APPROVAL',
    '9': 'COMPLETED',
    '0': 'OPEN'
  },
  CDOPENSTATUS: [
    0,
    2,
    3
  ],
  FILE_TYPES_OT: [144, 749],
  UOMID: {
    'SQ M': 11,
    'SQ FT': 12
  },
  CDTYPE:{
   "copy" : "COPY" ,
   "complete": "COMPLETE",
   "delete": "DELETE",
   "": ""
  }
};

