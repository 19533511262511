import { Component, OnInit } from "@angular/core";
import { LoaderService } from "@pulse/common";
@Component({
  selector: "app-powerbi",
  templateUrl: "./powerbi.component.html",
  styleUrls: ["./powerbi.component.scss"],
})
export class PowerbiComponent implements OnInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.showLoader(false);
  }
}
