import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { features, parentFeature, capabilites } from "@pulse/common";

import { PowerbiMetricsComponent } from "./powerbi-metrics/powerbi-metrics.component";
import { PowerbiComponent } from "./powerbi/powerbi.component";
const routes: Routes = [
  {
    path: "",
    component: PowerbiComponent,
    children: [
      {
        path: "metrics",
        component: PowerbiMetricsComponent,
      },
      // {
      //     path: 'pm_metrics',
      //     component: CBREMetricsComponent,
      //     data: { report: "PM Metrics" }
      // },
      // {
      //     path: 'pm_benchmarking',
      //     component: CBREMetricsComponent,
      //     data: { report: "PM Spend Benchmarking" }
      // },
      { path: "", pathMatch: "full", redirectTo: "metrics" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PowerBiRoutingModule {}
