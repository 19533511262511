import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { PulseFilterModule } from "@pulse/filter";

import {
  PulseCommonModule,
  HttpClientInterceptor,
  UserService,
} from "@pulse/common";
//import { LoaderComponent } from './shared/loader/loader.component';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FeatureFlagService, FeatureFlagGuardService } from "@pulse/common";
import { PulseNavModule } from "@pulse/nav";
import { appReducers } from "@pulse/common";
import { PulseContactsModule, MailModule } from "@pulse/contacts";
import { SharedModule } from "./shared/shared.module";
import { ChatComponent } from "./chat-bot/chat-bot.component";
import { PermissionService } from "./core/permission.service";
import { AdalService, WindowRef } from "@pulse/common";
import { AppLoadService } from "./app-load.service";
import { PropertyGroupModule } from "./property-group/property-group.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { UserIdleModule } from "angular-user-idle";
import {
  PulseNotificationModule,
  PulseNotificationService,
  PulseTableModule,
} from "@pulse/ui-library";
import { PendingChangesFlagGuardService } from "./core/pendingchanges-flag-guard.service";
import { PulseDatesModule } from "@pulse/dates";
import { environment } from "../environments/environment";
import { PageRefreshDialogueComponent } from "./shared/page-refresh-dialogue/page-refresh-dialogue.component";
import {
  PulseDialogModule,
  PulseTooltipModule,
  PulseDropDownsModule,
} from "@pulse/ui-library";
import { Constatnts } from "../assets/constants/constants";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { PulseHeaderFooterModule } from "@pulse/hnf";
import { ContactsMailModuleModule } from "./mail-module/mail-module.module";
import { TableauModule } from "./tableau/tableau.module";
import { PowerBiModule } from "./powerbi/powerbi.module";
import { QuiksightComponent } from "./quiksight/quiksight.component";
import { SafeUrl } from "./shared/pipes/safeurl.pipe";
import { UserlandingModule } from "./userlanding/userlanding.module";
import { ChatbotComponent } from "./chatbot/chatbot.component";
import { FeatureLibModule as UserlandingLibModule } from '@pulse/userlanding';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "./assets/i18n/",
    ".json?cb=" + new Date().getTime()
  );
}
export function startupServiceFactory(appLoadService: AppLoadService) {
  return () => {
    appLoadService.setEnv(environment.env);
    return appLoadService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    ChatbotComponent,
    PageRefreshDialogueComponent,
    QuiksightComponent,
    SafeUrl,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PulseNotificationModule,
    PulseContactsModule,
    MailModule,
    StoreModule.forRoot(appReducers),
    TranslateModule.forRoot(),
    TableauModule,
    PulseDatesModule,
    PulseCommonModule,
    PulseFilterModule,
    PulseDialogModule,
    PulseTooltipModule,
    UserIdleModule.forRoot({
      idle: Constatnts.SESSION_IDLE_TIME,
      timeout: Constatnts.SESSION_IDLE_TIMEOUT,
    }),
    PulseDropDownsModule,
    PulseHeaderFooterModule,
    PowerBiModule,
    PropertyGroupModule,
    PulseNavModule,
    ContactsMailModuleModule,
    PulseTableModule,
    UserlandingLibModule
  ],
  providers: [
    {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    AppLoadService,
    CurrencyPipe,
    PulseNotificationService,
    DecimalPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AppLoadService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    FeatureFlagGuardService,
    PendingChangesFlagGuardService,
    FeatureFlagService,
    UserService,
    PermissionService,
    AdalService,
    WindowRef,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
