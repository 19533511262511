import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { SubSink } from "subsink";
import { AppDataSharingService, AppNotificationService } from "@pulse/common";
import { PropertyGroupMappingResult } from "../../core/interfaces/propertyGroupMappingResult.model";
import { PropertyGroupDocumentsService } from "../property-group-documents.service";
import { DeletePropertyGroupService } from "./delete-property-group.service";
import { UserService } from "@pulse/common";
import { UserInfo } from "@pulse/common";
import { CommonService } from "../../shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-delete-property-group",
  templateUrl: "./delete-property-group.component.html",
  styleUrls: ["./delete-property-group.component.scss"],
})
export class DeletePropertyGroupComponent implements OnDestroy {
  style: any = { height: "270px", width: "500px" };
  @Output() hideDialog = new EventEmitter<string>();
  @Input() propertyGroup: PropertyGroupMappingResult;

  subs = new SubSink();
  constructor(
    private deletePropertyGroupService: DeletePropertyGroupService,
    private appNotificationService: AppNotificationService,
    private appDataSharingService: AppDataSharingService,
    private propertyGroupDocumentService: PropertyGroupDocumentsService,
    private userService: UserService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) { }

  close() {
    this.hideDialog.next("close");
  }

  handleSuccess() {
    this.close();
    this.appNotificationService.showSuccessNotication(
      this.translateService.instant('GROUP_DELETED_SUCCESS_MSG')
    );

    this.appDataSharingService.deletedGroupId.next(this.propertyGroup.id);
  }

  remove() {
    const postDataId = {} as UserInfo;
    postDataId.userID = this.userService.getUserId();
    // this.subs.add(
    //   this.commonService.clearCache(postDataId).subscribe((result) => {})
    // );
    this.subs.add(
      this.deletePropertyGroupService
        .deleteGroup(this.propertyGroup.id)
        .subscribe(
          (res) => {
            this.handleSuccess();
            this.propertyGroupDocumentService.updateCategories(
              this.extractProperties(),
              this.appDataSharingService.getOtdsTicket(),
              null,
              null
            );
          },
          (e) => {
            this.onSaveError(e);
          }
        )
    );
  }
  extractProperties() {
    let properties = [];
    properties.push(this.propertyGroup.primaryProperty);
    properties = properties.concat(this.propertyGroup.secondaryProperties);
    return properties;
  }
  onSaveError(e: any) {
    if (e.status === 200) {
      this.handleSuccess();
    } else {
      this.close();
      this.appNotificationService.showErrorNotication(
        this.translateService.instant('DELETE_GROUP_ERROR_MSG')
      );
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
