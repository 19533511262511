import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Property } from "../../../core/interfaces/property.model";
import { PropertyGroupMappingResult } from "../../../core/interfaces/propertyGroupMappingResult.model";
import { MenuService } from "../../../core/menu.service";
import {
  parentFeature,
  features,
  capabilites,
  UserService,
} from "@pulse/common";
import { AppDataSharingService } from "@pulse/common";
import { FeatureFlagService } from "@pulse/common";

@Component({
  selector: "app-group-card",
  templateUrl: "./group-card.component.html",
  styleUrls: ["./group-card.component.scss"],
})
export class GroupCardComponent implements OnInit, OnChanges {
  static readonly page_size = 4;
  capability = capabilites.PROPERTYGROUP;

  @Input()
  group: PropertyGroupMappingResult;
  showEditGroupIcon: boolean;
  isDeleteGroupPopupVisible: boolean;
  isEditGroupPopupVisible: boolean;
  showDeleteGroupIcon: boolean;
  showGroupIcons = false;
  carouselIconsArray: Number[];
  secondaryPropertiesToShow: Property[];
  selectedCarouselIndex = 0;
  imageLoadFailed = false;
  uomCode: string = "SQ FT";
  constructor(
    private menuService: MenuService,
    private appDataSharingService: AppDataSharingService,
    private featureFlag: FeatureFlagService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.showEditGroupIcon = this.featureFlag.functionOn(
      this.capability,
      parentFeature.PROPERTYGROUP,
      features.EDITPROPERTYGROUP
    );
    this.showDeleteGroupIcon = this.featureFlag.functionOn(
      this.capability,
      parentFeature.PROPERTYGROUP,
      features.DELETEPROPERTYGROUP
    );
    this.getSecondariesArrayByCarouselIndex(0);
    this.carouselIconsArray = this.getCarouselIconsArray();
    this.uomCode = this.userService.getUOM();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getSecondariesArrayByCarouselIndex(0);
  }

  showPopover(event) {
    event && event.stopPropagation();
    this.showGroupIcons = !this.showGroupIcons;
  }
  hidePopover(e) {
    if (!e.classList.contains("group-action-icon")) {
      this.showGroupIcons = false;
    }
  }
  getRouteUrl(id): string {
    return "/propertydetail/" + id + "/critical-dates";
  }

  getSecondariesArrayByCarouselIndex(carouselIndex: number) {
    this.selectedCarouselIndex = carouselIndex;
    if (this.group.secondaryProperties) {
      this.secondaryPropertiesToShow = this.group.secondaryProperties.slice(
        carouselIndex * GroupCardComponent.page_size,
        (carouselIndex + 1) * GroupCardComponent.page_size
      );
    }
  }

  getCarouselIconsArray() {
    let divide = 1;
    if (this.group.secondaryProperties) {
      divide =
        this.group.secondaryProperties.length / GroupCardComponent.page_size;
    }
    return Array(Math.ceil(divide));
  }
  toggleDeleteGroupDialog(event) {
    event && event.stopPropagation && event.stopPropagation();
    this.isDeleteGroupPopupVisible = !this.isDeleteGroupPopupVisible;
  }
  toggleEditGroupDialog(event) {
    event && event.stopPropagation && event.stopPropagation();
    this.isEditGroupPopupVisible = !this.isEditGroupPopupVisible;
  }
  onViewGroupClick() {
    this.appDataSharingService.viewGroupTriggered.next({
      id: this.group.id,
      name: this.group.name,
    });
  }
  setDefaultImage() {
    this.imageLoadFailed = true;
  }
}
