import { Component, OnInit, OnDestroy, Input } from '@angular/core';
@Component({
  selector: 'app-wiki-feature-detail',
  templateUrl: './features-detail-view.component.html',
  styleUrls: ['./features-detail-view.component.scss']
})
export class WikiFeatureDetailComponent implements OnInit {
  @Input() feature: any;
  expanded: boolean = false;
  subFeatures: [];
  main_feature: any;
  initialItems = 4;
  icons = {
    contacts: '../../../../assets/icons/contact_icon.svg',
    'document management': '../../../../assets/icons/folder_icon.svg',
    documents: '../../../../assets/icons/folder_icon.svg',
    'critical dates': '../../../../assets/icons/critical_date.svg',
    'property groups': '../../../../assets/icons/group_icon.svg',
    groups: '../../../../assets/icons/group_icon.svg',
    filters: '../../../../assets/icons/filter_list.svg',
    tenants: '../../../../assets/icons/tenants.svg',
    links: '../../../../assets/icons/link.svg',
    settings: '../../../../assets/icons/settings.svg',
    support: '../../../../assets/icons/support.svg',
    mobile: '../../../../assets/icons/mobile.svg',
    navigation: '../../../../assets/icons/navigation.svg',
    access: '../../../../assets/icons/access.svg',
    'business continuity': '../../../../assets/icons/business.svg',
    'forms': '../../../../../assets/icons/forms.svg',
    'emea': '../../../../../assets/images/emea.png',
    'property and portfolio setup': '../../../../../assets/icons/engineering.svg',
    'enterprise connect': '../../../../../assets/icons/change-circle.svg',
  };
  constructor() { }

  ngOnInit() {
    this.main_feature = this.feature.wikiFeatureModel;
    this.subFeatures = this.feature.wikiSubFeatureModelList.slice(0, 4);
  }

  ngOnDestroy() { }
  getImgSrc(name) {
    name = name.toLowerCase();
    return this.icons[name] ? this.icons[name] : this.icons.contacts;
  }

  expand(show_more_flag) {
    if (show_more_flag) {
      this.subFeatures = this.feature.wikiSubFeatureModelList;
      this.expanded = true;
    } else {
      this.subFeatures = this.feature.wikiSubFeatureModelList.slice(
        0,
        this.initialItems
      );
      this.expanded = false;
    }
  }
}
