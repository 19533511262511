import { Injectable } from '@angular/core';
import { UserService } from '@pulse/common';
import { Feature } from './interfaces/feature.model';
import { PermissionType } from './interfaces/permission-type.model';
import { UserInfo } from "@pulse/common";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  user: UserInfo;
  constructor(private userService: UserService) {
    this.user = userService.getUser();
  }

  canUpdateFeature(featureName: string): boolean {
    let canUpdate = false;
    this.user.features.forEach((feature: Feature) => {
      if (feature.name === featureName) {
        canUpdate = this.isRoleUpdate(feature);
      }
    });
    return canUpdate;
  }

  isRoleUpdate(feature: Feature) {
    let isRoleUpdate = false;
    if (feature.permissions.find(x => x === PermissionType.WRITE)) {
      isRoleUpdate = true;
    }
    return isRoleUpdate;
  }
}
