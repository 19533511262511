import { Component, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { SubSink } from "subsink";
import { Store } from "@ngrx/store";
import { IAppState, PropertySelectors, LoaderService, FolderBrowserWidget } from "@pulse/common";
//import { FolderBrowserWidget } from "../../../../assets/folderbrowser.js";
import { AppConfigService, AppDataSharingService, AppNotificationService } from "@pulse/common";
import { PropertiesDocumentsService } from "../../../properties/properties-documents/properties-documents.service";
import { Constatnts } from '../../../../assets/constants/constants';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-add-document",
  templateUrl: "./add-document.component.html",
  styleUrls: ["./add-document.component.scss"],
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  errorMsg: string;
  error: boolean = false;
  otdsToken;
  isLoaderVisible = false;
  private folderBrowserParent = "#newDocCtnr";
  @Output() hideDialog = new EventEmitter<string>();
  propertyId;
  showDocsWidget: boolean = false;
  subs = new SubSink();
  style = { "min-height": "40%" };
  constructor(
    private config: AppConfigService,
    private appDataSharingService: AppDataSharingService,
    private propertiesDocumentsService: PropertiesDocumentsService,
    private store: Store<IAppState>,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private notificationService: AppNotificationService
  ) { }

  ngOnInit() {
    this.showDocsWidget = true;
    this.otdsToken = this.appDataSharingService.getOtdsTicket();
    if (this.otdsToken) {
      this.propertyId = this.appDataSharingService.getPropertyId();
      if (this.propertyId) {
        this.subs.add(
          this.store
            .select(PropertySelectors.getProperty)
            .subscribe((property) => {
              if (property) {
                this.getNodeId(
                  this.propertiesDocumentsService.getPropNickName(property)
                );
              }
            })
        );
      } else {
        const data = this.appDataSharingService.getDocRootInfo();
        if (data) {
          this.renderFolderBrowserWidget(data);
        } else {
          this.getNodeId(this.propertiesDocumentsService.getRootFolderNickName());
        }
      }
    }
    else {
      this.error = true;
      this.errorMsg = this.translateService.instant(Constatnts.ERROR_MSG_USER_OT);
    }
  }

  getNodeId(nickName) {
    let post_obj = {
      nickname: nickName,
      ticket: this.appDataSharingService.getOtdsTicket(),
    };

    this.propertiesDocumentsService.getPropertyCSNodeId(post_obj).subscribe(
      (data) => {
        this.renderFolderBrowserWidget(data);
      },
      (err) => {
        this.error = true;
        this.errorMsg = err.error.message;
      }
    );
  }

  renderFolderBrowserWidget(data: any = {}) {
    this.showLoader(true);
    // this.loaderService.showLoader(true);
    let url_obj = {
      url: `${this.config.get("content_server_url")}`,
      supportPath: "/img",
    };

    FolderBrowserWidget(
      this.otdsToken,
      data,
      url_obj,
      this.folderBrowserParent,
      (object: any) => {
        this.showLoader(object);
      }

    );
    setTimeout(() => {
      this.showLoader(false);
      //this.loaderService.showLoader(false);
    }, 5000);
  }
  showLoader(flag: boolean) {
    this.isLoaderVisible = flag;
  }
  close() {
    this.hideDialog.next("close");
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
