import {
    Component,
    OnInit,
    Renderer2,
    ViewChild,
    ElementRef
} from '@angular/core';
import {
    UserService,
    LoaderService,
    AppDataSharingService
} from '@pulse/common';
import { Router } from '@angular/router';
//import { LoaderService } from './core/loader.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@pulse/common';
import { ToggleFilterPanel } from '@pulse/common';
import { AppConfigService } from '@pulse/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable, from, merge } from 'rxjs';
import { scan } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-chat-bot',
    templateUrl: './chat-bot.component.html',
    styleUrls: ['./chat-bot.component.scss']
})

export class ChatComponent implements OnInit {

    chatDiv = false;
    floatButton = true;
    todayDate: any;
    messages: any[] = [];
    typingShow = false;

    public user: any;

    public bot: any;
    @ViewChild('autocomplete_result') div: ElementRef;
    formValue: any;

    public imagePath;
    url: any;


    constructor(
        private userService: UserService,
        public router: Router,
        private loaderService: LoaderService,
        private appDataSharingService: AppDataSharingService,
        private renderer: Renderer2,
        private store: Store<IAppState>,
        private configService: AppConfigService,
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {
        this.renderer.addClass(document.body, 'hide-loader');

    }
    ngOnInit() {

        this.todayDate = new Date().toDateString();
        this.bot = {
            id: 0,
            text: this.getTimeAndWish()
        };
        this.messages.push(this.bot);
        //  this.chatBot();
    }

    onFloatClick() {
        this.chatDiv = true;
        this.floatButton = false;
    }

    closeBot() {
        this.chatDiv = false;
        this.floatButton = true;
    }


    saveNewWiki(value: any): Observable<any> {
        //const wikipostURL = "https://api-test.cbre.com:443/t/assetservices_us_assetview/dev/pm/pulseglobal-property/1.0.0/property/chatbot?question=" + value;
        //const wikipostURL = "https://api-test.cbre.com:443/t/assetservices_us_assetview/dev/pm/pulseglobal-chatbot/1.0.0/chatbot/data?question=" + value;
        const wikipostURL = "https://api-test.cbre.com:443/t/assetservices_us_assetview/dev/pm/pulseglobal-chatbot-demo/1.0.0/chatbot/data?question=" + value;
        let config = {
            headers: {
                'no-loader': `true`,
            }
        }
        return this.http.get<any>(wikipostURL, config);
    }


    chatBot(value: any) {
        this.typingShow = true;
        this.saveNewWiki(value).subscribe(res => {
            var responseValue = res[0].Answer[0];
            this.bot = {
                id: 0,
                text: responseValue
            };
            this.messages.push(this.bot);
            this.scrollDown();
            this.typingShow = false;
        });
    }

    storeData() {
        const myNode = document.getElementById("autocomplete_result");
        myNode.innerHTML = '';
        this.user = {
            id: 1,
            text: this.formValue
        };

        this.messages.push(this.user);
        this.chatBot(this.formValue);
        this.formValue = "";

    }

    suggestions() {
        const myNode = document.getElementById("autocomplete_result");
        myNode.innerHTML = '';
        var db = [
            "Get property count?",
            "Get critical date(cd) count?",
            "Get tenant count for property?",
            "property REM mappings?",
            "Critical dates for today"
        ];

        var inputValue = this.formValue;

        if (!inputValue) {
            myNode.innerHTML = '';
            return;
        }
        db.forEach((item) => {
            if (item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
                const p: HTMLParagraphElement = this.renderer.createElement('p');
                p.innerHTML = item;
                p.setAttribute('class', 'suggestionslist');
                this.renderer.listen(p, 'click', (event) => {
                    this.formValue = p.innerHTML;
                    this.storeData();
                    this.formValue = '';
                    myNode.innerHTML = '';
                });
                this.renderer.appendChild(this.div.nativeElement, p);
            }

        });

        this.scrollDown();
    }



    botFileChange(event) {
        var tmppath = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0])) as string;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = reader.result; //add source to image
                this.user = {
                    id: 1,
                    img: this.url
                };
                this.messages.push(this.user);
                this.bot = {
                    id: 0,
                    text: "Image is uploaded,Will reach you soon."
                }
                this.messages.push(this.bot);

            }

            this.scrollDown();
        }
    }

    getTimeAndWish() {
        var chatUserName = this.userService.getUserName();
        var today = new Date();
        var timeH = today.getHours()
        var msg = ""
        if (timeH < 12)
            msg = "Hi " + chatUserName + ",Good Morning.I'm Bob your personal Assistant.How can I help you?"
        else if (timeH < 18)
            msg = "Hi " + chatUserName + ",Good Afternoon.I'm Bob your personal Assistant.How can I help you?"
        else
            msg = "Hi " + chatUserName + ",Good Evening.I'm Bob your personal Assistant.How can I help you?"
        return msg;
    }

    scrollDown() {
        var messageBody = document.querySelector('.message-list');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
}