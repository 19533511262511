import { Component, OnInit } from '@angular/core';
import { UserService } from '@pulse/common';
import { QsService } from './qs.service'
@Component({
  selector: 'app-quiksight',
  templateUrl: './quiksight.component.html',
  styleUrls: ['./quiksight.component.scss']
})
export class QuiksightComponent implements OnInit {
  embedUrl;
  embedQbarUrl;
  dashboardId = "fdce692f-23fa-4d6d-bf14-513e5fe8d2fb";
  userId;
  accountId="576991982310"
  constructor(private userService: UserService, private qsService: QsService) { }
  
  ngOnInit(): void {
    this.userId = this.userService.getUserId()
    const request = {
      accountId:this.accountId,
      quicksightId: this.dashboardId,
      userArn: {
        "roleName":this.userId,
        "userName":this.userId
      }
    }
    this.qsService.getQsUsers(request).subscribe((res:any) => {
      const user = res.userList.find((user:any) => {
        if(user.email.toLocaleLowerCase() === this.userId.toLocaleLowerCase()) {
          return true
        }
        return false
      })
      if(user) {
        this.userId = user.userName
      }
      this.getAnonymEmbedUrl()
    }, e => {
      console.log(e)
    })
  }

  getEmbedUrl() {
    
    const request = {
      accountId:this.accountId,
      quicksightId: this.dashboardId,
      userArn: {
        "roleName":this.userId,
        "userName":this.userId
      }
    }
    this.qsService.getEmbedUrl(request).subscribe((result: any) => {
      this.embedUrl = result.EmbedUrl
    }, err=> {

    })
  }

  getAnonymEmbedUrl() {
    const userName = this.userService.getUserId()
    const request = {
      accountId: this.accountId,
      quicksightId: this.dashboardId,
      sessionTags: [{
            "key": "tag_source_db",
            "value": "aunz,yardice"
        },
        {
            "key": "tag_property_code",
            "value": "*"
        }
      ]
    }
    this.qsService.getAnonymEmbedUrl(request).subscribe((result: any) => {
      this.embedUrl = result.EmbedUrl
    }, err=> {

    })
  }

  getQBardEmbedUrl() {
    const userName = this.userService.getUserId()
    const request = {
      accountId:this.accountId,
      quicksightId: this.dashboardId,
      userArn: {
        "roleName":this.userId,
        "userName":this.userId
      }
    }
    this.qsService.getQEmbedUrl(request).subscribe((result: any) => {
      this.embedUrl = result.EmbedUrl
    }, err=> {

    })
  }

}
