import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from '@angular/core';import { CommonModule } from '@angular/common';
import { MailModule } from '@pulse/contacts'



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MailModule
  ],
  exports:[MailModule],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactsMailModuleModule { }
