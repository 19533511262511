import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorUtilService {
  constructor() { }

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  noWhitespaceValidatorWithNotRequired(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let isWhitespace = false;
      if (control.value) {
        isWhitespace = control.value.toString().trim().length === 0;
      }
      return !isWhitespace ? null : { whitespace: true };
    };
  }
}
