import { Injectable } from '@angular/core';
import { AppConfigService } from '@pulse/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QsService {
  constructor(private appConfig: AppConfigService, private http: HttpClient) { }

  getEmbedUrl(requestBody) {
    return this.http.post(this.appConfig.getApi('criticaldatesMetricsAPI') + '/genRegisteredUserEmbedUrl', requestBody);
  }

  getAnonymEmbedUrl(requestBody) {
    return this.http.post(this.appConfig.getApi('criticaldatesMetricsAPI') + '/genAnonymousUserEmbedUrl', requestBody);
  }

  getQEmbedUrl(requestBody) {
    return this.http.post(this.appConfig.getApi('criticaldatesMetricsAPI') + '/genQbarEmbedUrl', requestBody);
  }


  getQsUsers(request) {
    return this.http.post(this.appConfig.getApi('criticaldatesMetricsAPI') + '/userList',request)
  }

}
