import { Property } from './property.model';

export class PropertyGroupMappingResult {
  isGroup: boolean;
  name: string;
  id: number;
  totalArea: number;
  totalAreaFormatted?: string;
  primaryProperty: Property;
  secondaryProperties: Property[];
  propertyDetails: Property;
  constructor(obj: any) {
    this.isGroup = obj.isGroup || false;
    this.name = obj.name || '';
    this.id = obj.id || 0;
    this.totalArea = obj.totalArea || 0;
    this.totalAreaFormatted = obj.totalAreaFormatted || '0';
    this.primaryProperty = obj.primaryProperty || null;
    this.secondaryProperties = obj.secondaryProperties || null;
    this.propertyDetails = obj.propertyDetails || null;
  }
}
