import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AddEditPropertyGroupComponent } from "./add-edit/add-edit-property-group.component";
import { DeletePropertyGroupComponent } from "./delete/delete-property-group.component";
import { PulseDialogModule, PulseDropDownsModule } from "@pulse/ui-library";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddEditPropertyGroupComponent, DeletePropertyGroupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PulseDialogModule,
    PulseDropDownsModule,
    TranslateModule
  ],
  exports: [AddEditPropertyGroupComponent, DeletePropertyGroupComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class PropertyGroupModule { }
