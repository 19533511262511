import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnalyticsPresentationComponent } from "./analytics/analytics-presentation/analytics-presentation.component";
import { OverviewPresentationComponent } from "./overview/overview-presentation/overview-presentation.component";
import { DocumentsPresentationComponent } from "./documents/documents-presentation/documents-presentation.component";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { OverviewMiniCalendarComponent } from "./overview-mini-calendar/overview-mini-calendar.component";
//import { DatesModule } from "../critical-dates/dates.module";
import { PulseDialogModule, PulseTableModule } from "@pulse/ui-library";

import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { DonutGraphComponent } from "./donut-icon-graph/donut-graph.component";
import { FloorPipe } from "./pipes/floor.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { PulseDropDownsModule } from "@pulse/ui-library";
import { TrackScrollDirective } from "./directives/track-scroll/track-scroll.directive";
import { PropertyGroupModule } from "../property-group/property-group.module";
import { PropertyCardComponent } from "./overview/property-card/property-card.component";
import { PropertyDocumentComponent } from "./overview/property-document/property-document.component";
import { GroupCardComponent } from "./overview/group-card/group-card.component";
import { ScriptHackComponent } from "./script-hack/script-hack.component";
import { AddDocumentComponent } from "./documents/add-document/add-document.component";
import { NumberOnlyDirective } from "./directives/number-only/numericOnly.directive";
import { NumberWithDecimalDirective } from "./directives/number-with-decimal/numericWithDecimal.directive"
import { PulseCommonModule } from "@pulse/common";
import { PulseTooltipModule } from "@pulse/ui-library";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { WikiFeatureDetailComponent } from "./../shared/wiki-how/wiki-how-component/features-detail-view/features-detail-view.component";
@NgModule({
  declarations: [
    AnalyticsPresentationComponent,
    OverviewPresentationComponent,
    PropertyCardComponent,
    PropertyDocumentComponent,
    GroupCardComponent,
    DocumentsPresentationComponent,
    OverviewMiniCalendarComponent,
    UnauthorizedComponent,
    DonutGraphComponent,
    FloorPipe,
    TruncatePipe,
    TrackScrollDirective,
    AddDocumentComponent,
    NumberOnlyDirective,
    WikiFeatureDetailComponent,
    ScriptHackComponent,
    NumberWithDecimalDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PropertyGroupModule,
    PulseCommonModule,
    PulseDropDownsModule,
    PulseDialogModule,
    PulseTooltipModule,
    PulseTableModule,
    TranslateModule
  ],
  exports: [
    AnalyticsPresentationComponent,
    OverviewPresentationComponent,
    PropertyCardComponent,
    PropertyDocumentComponent,
    GroupCardComponent,
    DocumentsPresentationComponent,
    DonutGraphComponent,
    OverviewMiniCalendarComponent,
    TrackScrollDirective,
    AddDocumentComponent,
    NumberOnlyDirective,
    WikiFeatureDetailComponent,
    ScriptHackComponent,
    NumberWithDecimalDirective
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
