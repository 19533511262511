import { UserService, AdalService } from "@pulse/common";
import { Component, OnInit } from "@angular/core";
import { Constatnts } from "../../../assets/constants/constants";
import { UserIdleService } from "angular-user-idle";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-page-refresh-dialogue",
  templateUrl: "./page-refresh-dialogue.component.html",
  styleUrls: ["./page-refresh-dialogue.component.scss"],
})
export class PageRefreshDialogueComponent implements OnInit {
  // refreshInterval: number = Constatnts.REFRESH_INTERVAL;
  idleTimeout: number = Constatnts.SESSION_IDLE_TIMEOUT;
  showRefreshDialogue = false;
  isTimeout: boolean = false;
  timer: any;
  style = { width: "600px" };

  constructor(
    private userIdle: UserIdleService,
    private adalService: AdalService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.inititateApiCalls();
  }

  inititateApiCalls() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    if (this.userService.userInfo && this.userService.userInfo.accessToken) {
      this.userIdle.onTimerStart().subscribe((count) => {
        this.isTimeout = true;
        this.timer = this.getTimer(this.idleTimeout - count);
      });
    }

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.logout();
    });
  }

  // initiateRefreshTimer() {
  //   setTimeout(() => {
  //     this.showRefreshDialogue = true;
  //   }, this.refreshInterval);
  // }
  // refreshPage() {
  //   window.location.reload();
  // }

  continueSession() {
    this.userIdle.stopTimer();
    this.userIdle.startWatching();
    this.isTimeout = false;
  }

  endSession() {
    this.logout();
  }

  getTimer(value) {
    const minutes: any = Math.floor(value / 60);
    let min = minutes ? minutes + this.translateService.instant('MIN') : "";
    return min + (value - minutes * 60);
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.adalService.logout();
    
  }
}
