import { Injectable } from '@angular/core';
import { Constatnts } from '../../../assets/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {
  constructor() { }

  getDateKey(date, separatorChar?: string) {
    if (date) {
      const sep = separatorChar === undefined ? '-' : separatorChar;
      return `${date.getMonth() +
        1}${sep}${date.getDate()}${sep}${date.getFullYear()}`;
    }
    return '';
  }

  getDateByFormat(date, format?, separatorChar?) {
    if (date) {
      const sep = separatorChar === undefined ? '-' : separatorChar;
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      let dateString = '';
      switch (format) {
        case 'dd/mm/yy':
        case 'dd/mm/yyyy':
          dateString = `${day}${sep}${month}${sep}${year}`;
          break;
        case 'dd/yy/mm':
        case 'dd/yyyy/mm':
          dateString = `${day}${sep}${year}${sep}${month}`;
          break;
        case 'yy/dd/mm':
        case 'yyyy/dd/mm':
          dateString = `${year}${sep}${day}${sep}${month}`;
          break;
        case 'yy/mm/dd':
        case 'yyyy/mm/dd':
          dateString = `${year}${sep}${month}${sep}${day}`;
          break;
        case 'mm/yy/dd':
        case 'mm/yyyy/dd':
          dateString = `${month}${sep}${year}${sep}${day}`;
          break;
        default:
          dateString = `${month}${sep}${day}${sep}${year}`;
          break;
      }
      return dateString;
    }
    return '';
  }

  getMonthDateRange(date) {
    date.setDate(1);
    const start = this.getDateKey(date);
    date.setDate(31);
    const end = this.getDateKey(date);
    return { start, end };
  }

  getWeekDateRange(date) {
    date.setDate(date.getDate() - date.getDay());
    const startDate = this.getDateKey(date);
    date.setDate(date.getDate() + 6);
    const endDate = this.getDateKey(date);
    return { startDate, endDate };
  }

  getDateSuffix(date) {
    const day = date.getDate();
    let notation = 'th';
    if (day % 10 === 1 && day !== 11) {
      notation = 'st';
    } else if (day % 10 === 2 && day !== 12) {
      notation = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      notation = 'rd';
    }
    return day + notation;
  }

  isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  removeTimeInDate(date) {
    if (date) {
      date.setHours(0, 0, 0, 0);
    }
    return date;
  }

  toLocalString(date: Date) {
    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }
    if (date) {
      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())
    } else {
      return '';
    }

  };

  getCurrentMonthWithYear() {
    const today = new Date();
    return Constatnts.MONTHS[today.getMonth()] + ' ' + today.getFullYear();
  }

  getMaxDate(year) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + year);
    this.removeTimeInDate(date);
    return date;
  }

  addYearsToDate(date, years) {
    const dateObj = new Date(date);
    dateObj.setFullYear(date.getFullYear() + years);
    this.removeTimeInDate(dateObj);
    return dateObj;
  }

  static getMaxEventDate() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    return date;
  }

  getDaysDiff(date1, date2) {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    return Math.floor((time2 - time1) / (1000 * 60 * 60 * 24));
  }

  getDaysDifference(date1, date2) {
    const time1 = this.removeTimeInDate(date1).getTime();
    const time2 = this.removeTimeInDate(date2).getTime();
    return Math.floor((time2 - time1) / (1000 * 60 * 60 * 24));
  }

  getTimeZone(): any {
    let timeZone: any;
    let date = new Date();
    timeZone = (date.getTimezoneOffset() * -1).toString();
    return { timeZone }
  }
}
