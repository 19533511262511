import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataSharingService, DataStorageService, LoaderService, UserService } from '@pulse/common';
import { Intents } from './chatbot.config';
import { ChatbotService } from './chatbot-service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  showChatbot = false;
  intents = JSON.parse(JSON.stringify(Intents));

  // lexRuntime: AWS.LexRuntime;
  // dailogStateVal: DialogState;
  // chatMessages: Array<Messages> = [
  //   {
  //     Owner: "ChatBot",
  //     Message: "Hello, I can help you order flowers",
  //   },
  // ];
  //  "{"propertyexpenselist":"The data you have requested is not available for this property. Contact our support team if you believe that this is an error. ","propertynoilist":"The data you have requested is not available for this property. Contact our support team if you believe that this is an error. ","agingProperties":"The data you have requested is not available for this property. Contact our support team if you believe that this is an error. ","propertyrevenuelist":"The data you have requested is not available for this property. Contact our support team if you believe that this is an error. ","propertyOccupancylist":"Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary"}"
  sessionAttributes = {
    accessToken: this.userService.userInfo.accessToken,
    userName: this.userService.userInfo.email,
    displayName: this.userService.userInfo.displayName,
    isTenantSelectedFromOptions: "false",
    isPropertySelectedFromOptions: "false",
  }
  aggrements = [
    {
      id: 0,
      type: "management",
      path: "../../../assets/files/1 Havelock Street West Perth Management Agreement Abstract.pdf",
      fileType: "pdf",
      fileTypePath: "../../../assets/images/pdfImage.png",
      property: "1 Havelock Street West Perth",
      hidden: false,
      title: "PDF Document",
      showCustomQuery: false,
      custom: "5/29/25",
      showQueryResult: false,
      entity: "Client Name",
      entityName: "Havens Pvt Ltd",
      showEmail: true,
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3379733\\\",\\\"size\\\":231887,\\\"mime_type\\\":\\\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\\\",\\\"name\\\":\\\"400 Hillsborough PMA - CBRE Draft 6-1-2023.docx\\\",\\\"latestDocumentPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3986460\\\",\\\"nodeId\\\":3986460}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 1,
      type: "management",
      path: "../../../assets/files/1 Chandos Street, St Leonards Management Agreement Abstract 2023.04 (1).doc",
      fileType: "word",
      fileTypePath: "../../../assets/images/wordImage.png",
      property: "1 Chandos Street, St Leonards",
      hidden: false,
      title: "Open text document",
      showCustomQuery: false,
      custom: "7/14/26",
      showQueryResult: false,
      client: "QIC Limited",
      entity: "Client Name",
      entityName: "QIC Limited",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3379733\\\",\\\"size\\\":231887,\\\"mime_type\\\":\\\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\\\",\\\"name\\\":\\\"400 Hillsborough PMA - CBRE Draft 6-1-2023.docx\\\",\\\"latestDocumentPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3986460\\\",\\\"nodeId\\\":3986460}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 2,
      type: "lease",
      path: "../../../assets/files/Academic Assessment Services Pty Ltd - 1 Chandos Street Lease Agreement.docx",
      fileType: "word",
      fileTypePath: "../../../assets/images/wordImage.png",
      property: "1 Chandos Street, St Leonards",
      hidden: false,
      title: "Open text document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Australian Institute",
      showQueryResult: false,
      entity: "Tenant Name",
      entityName: "Australian Institute",
      showEmail: true,
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Chandos Street, St Leonards is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3379733\\\",\\\"size\\\":231887,\\\"mime_type\\\":\\\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\\\",\\\"name\\\":\\\"400 Hillsborough PMA - CBRE Draft 6-1-2023.docx\\\",\\\"latestDocumentPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3986460\\\",\\\"nodeId\\\":3986460}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 3,
      type: "lease",
      path: "../../../assets/files/AAA Nextt Group Pty Ltd - Garden Square Complex Lease Agreement.pdf",
      fileType: "pdf",
      fileTypePath: "../../../assets/images/pdfImage.png",
      property: "Garden Square Complex",
      hidden: false,
      title: "PDF Document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Mecon Insurance Pty Ltd",
      showQueryResult: false,
      entity: "Tenant Name",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      entityName: "Mecon Insurance Pty Ltd",
      arrearsRes: "Total Arrears for Garden Square Complex is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for Garden Square Complex is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for Garden Square Complex is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for Garden Square Complex is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for Garden Square Complex is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3379733\\\",\\\"size\\\":231887,\\\"mime_type\\\":\\\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\\\",\\\"name\\\":\\\"400 Hillsborough PMA - CBRE Draft 6-1-2023.docx\\\",\\\"latestDocumentPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3986460\\\",\\\"nodeId\\\":3986460}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 4,
      type: "lease",
      path: "../../../assets/files/AMEC Pty Ltd - 1 Havelock Street West Perth Lease Agreement.docx",
      fileType: "word",
      fileTypePath: "../../../assets/images/wordImage.png",
      property: "1 Havelock Street West Perth",
      hidden: false,
      title: "Open text document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Gilmac Pty Ltd",
      showQueryResult: false,
      entity: "Tenant Name",
      entityName: "Gilmac Pty Ltd",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Havelock Street West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3379733\\\",\\\"size\\\":231887,\\\"mime_type\\\":\\\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\\\",\\\"name\\\":\\\"400 Hillsborough PMA - CBRE Draft 6-1-2023.docx\\\",\\\"latestDocumentPath\\\":\\\"https://ecmdev.cbre.com/otcs/cs/open/3986460\\\",\\\"nodeId\\\":3986460}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 5,
      type: "lease",
      path: "../../../assets/files/Australian Institute of Credit Management  - 1 Chandos Street Lease Agreement.pdf",
      fileType: "pdf",
      fileTypePath: "../../../assets/images/pdfImage.png",
      property: "1 Chandos Street, St Leonards 1",
      hidden: false,
      title: "PDF Document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Australian Institute",
      showQueryResult: false,
      entity: "Tenant Name",
      entityName: "Australian Institute",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Chandos Street, St Leonards 1 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Chandos Street, St Leonards 1 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Chandos Street, St Leonards 1 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Chandos Street, St Leonards 1 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Chandos Street, St Leonards 1 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812087\\\",\\\"latestDocumentPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812088\\\"}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 6,
      type: "lease",
      path: "../../../assets/files/Bennett Murada Pty Ltd - 1 Chandos Street Lease Agreement.docx",
      fileType: "word",
      fileTypePath: "../../../assets/images/wordImage.png",
      property: "1 Chandos Street, St Leonards 2",
      hidden: false,
      title: "Open text document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Bennett Murada Pty Ltd",
      showQueryResult: false,
      entity: "Tenant Name",
      entityName: "Bennett Murada Pty Ltd",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Chandos Street, St Leonards 2 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Chandos Street, St Leonards 2 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Chandos Street, St Leonards 2 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Chandos Street, St Leonards 2 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Chandos Street, St Leonards 2 is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812087\\\",\\\"latestDocumentPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812088\\\"}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
    {
      id: 7,
      type: "management",
      path: "../../../assets/files/1 Havelock Street West Perth Management Agreement Abstract (1).doc",
      fileType: "word",
      fileTypePath: "../../../assets/images/wordImage.png",
      property: "1 Havelock Stree West Perth",
      hidden: false,
      title: "Open text document",
      showCustomQuery: false,
      custom: "7/14/26",
      tenant: "Bennett Murada Pty Ltd",
      showQueryResult: false,
      client: "QIC Limited",
      entity: "Client Name",
      entityName: "QIC Limited",
      docSummarize: {
        "message": "Summary: \nThe chat history provides insights into the handling of user greetings and the summarization of context-based queries. The user is advised to respond appropriately to greetings without needing any source documents. For context-based questions, the assistant requires a provided context to give accurate answers. In case the context is not provided or the question is not covered in the context, the response would be \"Unknown\". \n\nKey Points: \n\n- Respond appropriately to user greetings without any source documents.\n- The assistant requires context to provide accurate answers to context-based questions.\n- If context is not provided or the question is not covered in the context, the assistant will respond with \"Unknown\".\n- If asked a question like the number of days in a week and the knowledge base does not contain this information, the assistant will respond with \"Unknown\".",
        "source_information": "[Document(page_content='Intentionally Omitted.  \\n\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\tIntentionally Omitted.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='the communication is addressed as set forth below:', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'}), Document(page_content='– BUDGETS, REPORTS, AND FINANCIAL SERVICES', metadata={'source': '//source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx'})]"
      },
      arrearsRes: "Total Arrears for 1 Havelock Stree West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      noiRes: "Total NOI for 1 Havelock Stree West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      revenueRes: "Total Revenue for 1 Havelock Stree West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      expensesRes: "Total Expenses for 1 Havelock Stree West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      occupancyRes: "Total Occupancy for 1 Havelock Stree West Perth is 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary",
      managementAgreementRes: "{\"intentName\":\"ManagementAgreement\",\"type\":\"text\",\"message\":\"{\\\"folderPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812087\\\",\\\"latestDocumentPath\\\":\\\"https://ecmtest.cbre.com/otcs/cs/open/6812088\\\"}\"}",
      summariseRes: { "propertyexpenselist": "Total Expenses 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertynoilist": "Total NOI 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "agingProperties": "Total Arrears 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary", "propertyrevenuelist": "Total Revenue 85.51% More Details:https%3A%2F%2Fpulse-pes.cbre.com%2Fanalytics%2Fsummary ", "propertyOccupancylist": "Total Occupancy 85.51% More Details:https%3A%2F%2Fpulse-test.cbre.com%2Fanalytics%2Fsummary" }

    },
  ];

  ChatInput = "";
  lexUserId = "userID" + Date.now();

  responseMessage = "";
  messages: any[] = [];
  bot;

  inputText;
  intentVal: any;
  propertyId: number;
  documentFooterEnable: boolean = false;
  documentDetails: any;
  sourceArr: any[];
  expand: boolean = false;
  sessionId: any;

  constructor(
    private userService: UserService,
    private appDataSharingService: AppDataSharingService,
    private router: Router,
    private chatbotService: ChatbotService,
    private storageService: DataStorageService,
  ) { }

  ngOnInit() {
    this.setAWSConfig();
  }

  setAWSConfig() {
    // AWS.config.region = "us-east-1"; // Region
    // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //   IdentityPoolId: "us-east-1:18880ef6-626a-42e3-aa72-fdf3674b9ed2", //your Identity poolId
    // });
    // this.lexRuntime = new AWS.LexRuntime();

  }
  shareSummary(summaryVal) {


    var obj = {
      context: summaryVal,
      prompt: "Write a professional email body based on the data given without subject?"
    }
    this.chatbotService.chatInteractSmartSearch(obj).subscribe((res) => {
      if (res['message']) {
        const routeState = {
          contacts: [],
          isPropertyView: true,
          isEditContact: false,
          "prop": {
            "id": "this.propDetails.buildingId",
            "name": "this.propDetails.buildingName",
          },
          navigatePage: window.location.pathname,
          isContactFormReset: true,
          messageVal: res['message'].replaceAll('\n', '<br>'),
        }
        sessionStorage.mailRouteState = JSON.stringify(routeState)
        this.router.navigate(['mail/email-template']);
        this.toggleChatbot()
      }
    }, error => {
      console.log(error)
    });



  }
  addIntentMessages() {
    this.bot = {
      id: 0,
      text: "Hi, What can I  help you with today?",
      intro: true,
    };
    this.pushObjToMessages(this.bot);
    this.pushObjToMessages({
      id: 0,
      text: this.intents,
      array: true,
      basicIntents: true,
    });
  }

  sendIntent(intent, addShowMy = false) {
    // if(!clickable){
    //   return
    // }
    if (addShowMy) {
      this.bot = {
        id: 1,
        text: `${intent.name}`,
      };
      this.pushObjToMessages(this.bot);
      // let x = this.messages.find((el) => el.basicIntents);
      // x.text = x.text.filter((ex) => ex.name == msg);
      // x.clickable = false;
      this.messages = this.messages.filter((el) => !el.basicIntents);
    } else {
      this.bot = {
        id: 1,
        text: this.inputText,
      };
      // intent.value = this.inputText;
      this.pushObjToMessages(this.bot);
    }
    var sessionAttributes = {
      accessToken: this.userService.userInfo.accessToken,
      userName: this.userService.userInfo.email,
      displayName: this.userService.userInfo.displayName,
      isTenantSelectedFromOptions: "false",
      isPropertySelectedFromOptions: "false",
    }
    this.intentVal = intent.value;
    // this.pushObjToMessages(this.bot);
    if (intent.value == "summarize management") {
      this.startChat2(intent.value, sessionAttributes, false);
    } else {
      this.startChat2(intent.value, sessionAttributes, true);
    }

  }

  async startChat2(msgVal, obj, intentState) {
    // this.chatMessages.push({ Owner: "User", Message: msgVal });
    this.ChatInput = "";
    const params = {
      botAlias: "ChatBotTestEnvAlias", //
      botName: "ChatBotTestEnv", // your chatbot name
      userId: this.lexUserId,
      inputText: msgVal,
      sessionAttributes: obj,
    };
    if (intentState) {
      this.bot = {
        id: 0,
        text: "Please select the property",
        select: true,
      };

      this.pushObjToMessages(this.bot);
      this.bot = {
        id: 0,
        properties: true,
        text: this.aggrements,
      };

      this.pushObjToMessages(this.bot);

    } else {
      if (this.intentVal == "summarize a property") {

        this.bot = {
          id: 0,
          text: this.aggrements[this.propertyId].summariseRes,
          summarize: true,
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "management agreement") {
        let intentRes = JSON.parse(this.aggrements[this.propertyId].managementAgreementRes)
        let mesRes = JSON.parse(intentRes?.message);
        mesRes.propertyName = this.aggrements[this.propertyId].property;
        mesRes.propDetails = this.aggrements[this.propertyId]
        this.accessCreationForGPT(mesRes, true);
        // this.messages = this.messages.filter((el) => !el.basicIntents);
        // this.pushObjToMessages({
        //   id: 0,
        //   text: this.intents,
        //   array: true,
        //   basicIntents: true,
        // });
      } else if (this.intentVal == "noi") {
        this.responseMessage = this.aggrements[this.propertyId].noiRes;
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "expenses") {
        this.responseMessage = this.aggrements[this.propertyId].expensesRes;
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "revenue") {
        this.responseMessage = this.aggrements[this.propertyId].revenueRes;
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "occupancy") {
        this.responseMessage = this.aggrements[this.propertyId].occupancyRes;
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "arrears") {
        this.responseMessage = this.aggrements[this.propertyId].arrearsRes;
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      } else if (this.intentVal == "summarize management") {

        // let res = this.aggrements[this.propertyId].docSummarize
        // console.log(res)

        // this.inputText = ""

        // this.documentFooterEnable = true
        // // res['message'] = this.transform(res['message']);
        // this.sourceArr = []
        // let obj = {
        //   "info": "Entire Agreement; Amendment.  This Agreement contains the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior oral or written agreements, understandings, or arrangements with respect to such subject matter.  This Agreement may not be amended or modified except by a writing signed by both parties.",
        //   "source": "source_docs/400 Hillsborough PMA - CBRE Draft 6-1-2023.docx"
        // }
        // this.sourceArr.push(obj)


        // // res.source_information = sourceArr


        // let bot = {
        //   id: 0,
        //   text: res,
        //   gptAnswers: true
        // };
        // this.pushObjToMessages(bot);
        let objVal =
        {
          "session-id": this.sessionId,
          "prompt": this.inputText,
          "history": ""
        }

        this.chatbotService.chatBotInteraction(objVal).subscribe((res) => {

          this.inputText = ""
          this.documentFooterEnable = true
          // res['message'] = this.transform(res['message']);
          var stringVal = res['source_information'].replaceAll(/[\[\]]/g, " ");
          let arr = stringVal.split("Document(");

          let sourceArr = []
          for (var i = 0; i < arr.length; i++) {
            if (arr[i] != " ") {
              var stringVal = arr[i].replaceAll("'})", " ").replaceAll("page_content='", "").replaceAll("'//", "").replaceAll("',", "").replaceAll("\\n\\n\\n\\n", "\n").replaceAll("\\n\\n\\n", "\n").replaceAll("\\n\\n", "\n").replaceAll("\\n", "\n").replaceAll("\\t", "\t");
              arr[i] = stringVal.split("metadata={'source': ");
              var sourceObj = arr[i];
              var obj = {
                "info": sourceObj[0],
                "source": sourceObj[1]
              }
              sourceArr.push(obj)
            }
          }
          res['source_information'] = sourceArr


          let bot = {
            id: 0,
            text: res,
            sessionId: this.sessionId,
            documentDetails: this.documentDetails,
            propDetails: this.aggrements[this.propertyId],
            gptAnswers: true
          };
          this.pushObjToMessages(bot);
        });

      } else {
        this.responseMessage = "The data you have requested is not available for this property. Contact our support team if you believe that this is an error.";
        this.bot = {
          id: 0,
          text: this.responseMessage,
          encodeNeeded: this.formatStringVal(this.responseMessage)
        };

        this.pushObjToMessages(this.bot);

        this.messages = this.messages.filter((el) => !el.basicIntents);
        this.pushObjToMessages({
          id: 0,
          text: this.intents,
          array: true,
          basicIntents: true,
        });
      }
    }



  }
  accessCreationForGPT(mesRes: any, state) {
    this.documentDetails = mesRes

    let obj =
    {
      "fileName": mesRes.name,
      "category": "Management Agreement",
      "nodeId": mesRes.nodeId,
      "session-id": null
    }

    this.chatbotService.createGPTAccess(obj).subscribe((res) => {
      if (res) {
        this.sessionId = res["session-id"]
        if (state) {
          let bot = {
            id: 0,
            text: mesRes,
            managementAgreement: true,
          };

          this.pushObjToMessages(bot);
        }

      }

    });
  }
  expandHandle() {
    this.expand = !this.expand;
  }
  formatStringVal(msg) {
    if (msg.includes("More Details:")) {
      return true
    } else {
      return false
    }

  }

  homeButton() {
    this.messages = this.messages.filter((el) => !el.basicIntents);
    this.pushObjToMessages({
      id: 0,
      text: this.intents,
      array: true,
      basicIntents: true,
    })
    this.documentFooterEnable = false
  }

  routeToEmailModuleAndAttachOTFile(obj) {
    const routeState = {
      contacts: [],
      isPropertyView: true,
      isEditContact: false,
      "prop": {
        "id": 16504,
        "name": "02 Renaissance Center - T100",
      },
      navigatePage: window.location.pathname,
      isContactFormReset: true,
      otVal: [{
        "name": obj.name,
        "otNodeId": obj.nodeId,
        "size": obj.size,
        "mime_type": obj.mime_type
      }]
    }
    sessionStorage.mailRouteState = JSON.stringify(routeState)
    this.router.navigate(['mail/email-template']);
    this.toggleChatbot()
  }

  routeToFolder(path) {
    var pathArr = path.split("/open/")
    this.appDataSharingService.setDocRootInfo({
      "id": pathArr[1]
    });
    this.router.navigate(['properties/documents']);
    this.toggleChatbot()
  }
  summarizeTheDocument(val) {
    this.inputText = "summarize document"
    this.sendIntent({ name: "Summarize Management", value: "summarize management" });

  }
  sendIntentClick(val) {
    this.sendIntent({ name: "Summarize Management", value: "summarize management" });
  }
  selectedString(item) {
    this.messages = this.messages.filter((el) => !el.properties);

    // let x = this.messages.find((el) => el.properties);
    // x.text = x.text.filter((ex) => ex.buildingName == item.buildingName);
    // x.clickable = false;
    this.propertyId = item?.id
    this.bot = {
      id: 1,
      text: item?.property,
    };

    this.pushObjToMessages(this.bot);
    var sessionAttributes = {
      accessToken: this.userService.userInfo.accessToken,
      userName: this.userService.userInfo.email,
      displayName: this.userService.userInfo.displayName,
      isTenantSelectedFromOptions: "false",
      isPropertySelectedFromOptions: "false",
      propertyName: item?.buildingName,
      buildingId: `${item?.buildingId}`,
      sourceDB: item?.sourceDB,
      OTDSTicket: this.appDataSharingService.getOtdsTicket(),
    };
    this.startChat2(item?.buildingCode, sessionAttributes, false);
    // this.chatMessages.push({ Owner: 'User', Message: "rcof01" });
  }


  toggleChatbot() {

    this.showChatbot = !this.showChatbot;
    this.messages = this.storageService.readFromSessionStorage("chatMessages") ? JSON.parse(this.storageService.readFromSessionStorage("chatMessages")) : []

    if (this.showChatbot) {
      if (this.messages.length == 0) {
        this.addIntentMessages();
        this.documentFooterEnable = false
      } else {
        let lengthVal = this.messages.length - 1

        if (this.messages[lengthVal].gptAnswers) {
          this.documentFooterEnable = true
          this.sessionId = this.messages[lengthVal]["sessionId"];
          this.documentDetails = this.messages[lengthVal]["documentDetails"];
        }
        if (this.messages[lengthVal].managementAgreement) {
          this.documentFooterEnable = false;
          this.accessCreationForGPT(this.messages[lengthVal]["text"], false);

        }
      }

    }
  }
  pushObjToMessages(obj) {
    this.messages.push(obj);
    this.storageService.writeToSessionStorage("chatMessages", JSON.stringify(this.messages));

  }
  closeChatbot() {
    this.showChatbot = !this.showChatbot;
    this.messages = [];
    this.storageService.removeToSessionStorage("chatMessages");
  }
  handleAsideClick(eve: Event) {
    eve.stopPropagation();
  }

  getLinkDetails(res) {
    if (res?.includes("More Details:")) {
      let z = res.split("More Details:").pop();
      let y = decodeURIComponent(z);
      return y;
    } else {
      return "";
    }
  }


  cutOccupancy(res) {
    if (res?.includes("More Details:")) {
      let z = res.split("More Details:").shift();
      return z;
    } else {
      return res;
    }
  }
}

export interface Messages {
  Owner: string;
  Message: string;
}
