import { Injectable } from '@angular/core';
import { Property } from '../core/interfaces/property.model';
import { PropertiesDocumentsService } from '../properties/properties-documents/properties-documents.service';
import { AppDataSharingService } from '@pulse/common';

@Injectable({
  providedIn: 'root'
})
export class PropertyGroupDocumentsService {
  constructor(
    private documentService: PropertiesDocumentsService,
    private appDataSharingService: AppDataSharingService) { }

  updateCategories(
    properties: Property[],
    ticket: any,
    groupId: number,
    groupName: string
  ) {
    const categoryMetadataList = [];
    properties.forEach(property => {
      categoryMetadataList.push({
        groupID: groupId || 0,
        groupName: groupName || null,
        isPrimaryProperty:
          property.isPrimaryBuilding &&
            property.isPrimaryBuilding.toLowerCase() === 'y'
            ? true
            : false,
        sourceSystemName: property.sourceDB,
        sourcePropertyCode: property.code,
        propertyID: property.id
      });
    });

    const post_obj = {
      ticket: ticket,
      data: categoryMetadataList
    };
    this.appDataSharingService.setHideLoader(true);

    this.documentService.updateCategoriesByNicknames(post_obj).subscribe(
      status => {
        this.appDataSharingService.setHideLoader(false);

      },
      error => {
        this.appDataSharingService.setHideLoader(true);

        console.log(error);
      }
    );
  }
}
