import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberWithDecimal]'
})
export class NumberWithDecimalDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<number> = [8, 9, 35, 36, 37, 39, 46];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    const keyCode = event.which || event.keyCode || 0;

    if (this.specialKeys.indexOf(event.keyCode) > -1 ||
      (keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (keyCode === 86 && event.ctrlKey === true) || // Allow : Ctrl+V
      (keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (keyCode === 90 && event.ctrlKey === true) // Allow: Ctrl+Z)
    ) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    let next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    // next = next.substring(0, next.length - 1);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: KeyboardEvent) {
    const pastedData = e['clipboardData'].getData('Text');
    if (isNaN(pastedData)) {
      e.preventDefault();
    }
  }
}
