import { Injectable } from "@angular/core";
import { AdalService, DataStorageService } from "@pulse/common";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import {
  AppConfigService,
  UserService,
  AppDataSharingService,
  StoreOtdsToken,
  IAppState,
} from "@pulse/common";
import { UserInfo } from "@pulse/common";
import { Constatnts } from "../assets/constants/constants";
@Injectable({
  providedIn: "root",
})
export class AppLoadService {
  isGetOtdsFirstTime = true;
  environment: string;

  constructor(
    private config: AppConfigService,
    private adalService: AdalService,
    private userService: UserService,
    private store: Store<IAppState>,
    private appDataSharingService: AppDataSharingService,
    private dataStorageService: DataStorageService
  ) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.config.setEnv(this.environment);
      this.config.load().subscribe((result1) => {
        this.authenticate().subscribe((result2) => {
          resolve();
        });
      });
    });
  }

  setEnv(env: string) {
    this.environment = env;
  }

  authenticate(): Observable<any> {
    return new Observable((subscriber) => {
      this.adalService.adalConfigSetup();
      this.isAuthenticated().then((result) => {
        this.userService.getUserInfoFromAPI().subscribe(
          (userPromise: Promise<any>) => {
            userPromise
              .then((user: UserInfo) => {
                this.handleValidateLoginSuccess(user, subscriber);
              })
              .catch((e) => {
                if (e && e.message === "Invalid session id") {
                  this.handleInvalidSession(subscriber);
                } else {
                  subscriber.next();
                  subscriber.complete();
                }
              });
          },
          (error) => {
            subscriber.next();
            subscriber.complete();
          }
        );
      });
    });
  }

  handleValidateLoginSuccess(user, subscriber) {
    if (user.email) {
      this.setSessionInfo(user);
      this.getOtdsTicket();
      setInterval(() => {
        this.getOtdsTicket();
      }, Constatnts.OTDS_INTERVAL_TIME);
    }
    subscriber.next();
    subscriber.complete();
  }

  handleInvalidSession(subscriber) {
    localStorage.clear();
    this.adalService.login();
  }

  setSessionInfo(user) {
    if (user && user.sessionId) {
      this.dataStorageService.writeToLocalStorage(
        Constatnts.SESSION_KEY,
        user.sessionId
      );
      const date = new Date();
      date.setMinutes(date.getMinutes() + Constatnts.SESSION_MINS);
      this.dataStorageService.writeToLocalStorage(
        Constatnts.SESSION_EXPIRE,
        `${date.getTime()}`
      );
    }
  }

  getOtdsTicket() {
    if (this.isGetOtdsFirstTime) {
      this.store.dispatch(new StoreOtdsToken("loading"));
    }
    this.userService.getOTDSToken().subscribe(
      (data) => {
        this.appDataSharingService.setOtdsTicket(data);
        if (this.isGetOtdsFirstTime) {
          this.store.dispatch(new StoreOtdsToken(data));
          this.isGetOtdsFirstTime = false;
        }
      },
      (error) => {
        this.store.dispatch(new StoreOtdsToken(null));
      }
    );
  }

  isAuthenticated(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.config.get("local")) {
        this.adalService.handleLogin().then(() => {
          resolve();
        });
        // if (this.config.get('authType') === 1) {
        //   setInterval(() => {
        //     this.refreshTokenOnTimer();
        //   }, Constatnts.TOKEN_TTL - Constatnts.TWO_MINS);
        // }
      } else {
        resolve();
      }
    });
  }

  refreshTokenOnTimer() {
    const key = localStorage.getItem("adal.token.keys");
    this.dataStorageService.writeToLocalStorage(
      `adal.expiration.key${key.replace(/\|/, "")}`,
      "0"
    );
    this.adalService.getTokenSilently();
  }
}
