import {
  Component,
  OnInit,
  Input,
  Output,
  Inject,
  Injectable
} from '@angular/core';
import { DOCUMENT } from "@angular/common";

import {
  AppConfigService,
  AppDataSharingService,
  AppNotificationService
} from '@pulse/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-document-card',
  templateUrl: './property-document.component.html',
  styleUrls: ['./property-document.component.scss']
})
export class PropertyDocumentComponent implements OnInit {
  public isLoaderVisible = false;
  private selectedProperty: any = {};
  private errorMsg = 'No folder is created for this property in content server';
  private getDataByNicknameAPI = 'Documents/GetDocumentDataByNickname';
  @Input()
  properties: any;
  docModelBoolean: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private appConfig: AppConfigService,
    private notificationService: AppNotificationService,
    private appDataSharingService: AppDataSharingService,
    private http: HttpClient
  ) {}
  ngOnInit() {
    // this.cleardom();
    // if (this.properties && this.properties.length > 0) {
    //   this.onSelect(this.properties[0]);
    // }
  }

  // cleardom() {
  //   let element = this.document.getElementById('folder-wrapper');
  //   if (element) {
  //     element.innerText = '';
  //   }
  // }
  // onSelect(property: any) {
  //   this.getNodeId(property);
  // }

  // async getNodeId(property: any) {
  //   let post_obj = {
  //     nickname: 'AV_' + property.name.replace(/ /g, '_').replace(/-/g, '_'),
  //     ticket: this.appDataSharingService.getOtdsTicket()
  //   };
  //   let otds_api = `${this.appConfig.getApi('documentsAPI')}${
  //     this.getDataByNicknameAPI
  //   }`;
  //   this.isLoaderVisible = true;

  //   this.http
  //     .post(otds_api, post_obj)
  //     .toPromise()
  //     .then(data => {
  //       this.renderFolderBrowserWidget(data, property);
  //     })
  //     .catch(err => {
  //       this.notificationService.showErrorNotication(this.errorMsg);
  //       this.isLoaderVisible = false;
  //       throw err;
  //     });
  // }

  // renderFolderBrowserWidget(data: any = {}, property: any) {
  //   this.selectedProperty = property;

  //   let url_obj = {
  //     url: `${this.appConfig.get('content_server_url')}`,
  //     supportPath: '/img'
  //   };
  //   let otdsToken = this.appDataSharingService.getOtdsTicket();
  //   this.cleardom();
  //   FolderBrowserWidget(otdsToken, data, url_obj, null);
  //   setTimeout(() => {
  //     this.isLoaderVisible = false;
  //   }, 3000);
  // }
}
