import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Country } from "../../core/interfaces/country.model";
import { Language } from "../../core/interfaces/language.model";
import { Currency } from "../../core/interfaces/currency.model";
import { UOM } from "../../core/interfaces/uom.model";
import { AppConfigService } from "@pulse/common";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient, private config: AppConfigService) {}

  // clearCacheUrl = 'PropertyGroup/ClearCache'

  getAllCountries(): Observable<any> {
    return this.http.get<any>(
      this.config.getApi("GlobalScope") + "common/getAllCountries"
    );
  }

  getAllLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(
      `${this.config.getApi("api")}Common/GetAllLanguages`
    );
  }

  getAllCurrency(): Observable<Currency[]> {
    return this.http.get<Currency[]>(
      `${this.config.getApi("api")}Common/GetAllCurrency`
    );
  }

  getAllUOM(): Observable<UOM[]> {
    return this.http.get<UOM[]>(`${this.config.getApi("api")}Common/GetAllUOM`);
  }

  // clearCache(obj: {}): any {
  //   return this.http.post<any>(
  //     this.config.getApi('propertyAPI') + this.clearCacheUrl,
  //     obj
  //   );
  // }
}
