export const Intents = [
  { name: "Show my Arrears",value:"arrears" },
  // { name: "Critical Dates" },
  // { name: "Tenant Lease Expiry" },
  // { name: "Cash Balance" },
  { name: "Show my Revenue",value:"revenue" },
  { name: "Show my Expenses",value:"expenses" },
  { name: "Show my Occupancy",value:"occupancy" },
  { name: "Show my NOI",value:"noi" },
  { name: "Show my Management agreement",value:"management agreement" },
  { name: "Summarize a property",value:"summarize a property" },
];
