import { Component, OnInit, Input } from '@angular/core';
import { DateUtilService } from '../../services/date-util.service';
import { Property } from '../../../core/interfaces/property.model';
import { MobileService } from 'src/app/core/mobile.service';

@Component({
  selector: 'app-overview-presentation',
  templateUrl: './overview-presentation.component.html',
  styleUrls: ['./overview-presentation.component.scss']
})
export class OverviewPresentationComponent implements OnInit {
  @Input()
  properties: Property[];

  constructor(private dateUtil: DateUtilService, public mbl: MobileService) { }

  ngOnInit() { }

  getRouteUrl(id): string {
    return '/propertydetail/' + id + '/critical-dates';
  }

  getStyleObj(id) {
    return {
      'background-image': `url(/assets/images/${(id % 10) + 1}.jpg)`
    };
  }
}
