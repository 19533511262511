import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@pulse/common';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'no-loader': 'yes'
  })
};
@Injectable({
  providedIn: 'root'
})
export class PropertiesDocumentsService {
  getDataByNicknameAPI = 'document/GetDocumentDataByNickname';
  getWebReportForAddTenantToPropertyAPI =
    'document/GetWebReportForAddTenantToProperty';
  UpdateCategoriesByNodeId = 'Documents/UpdateCategoriesByNodeId';
  UpdateCategoriesByNicknames = 'document/AssignPropertyGroups';

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getPropertyCSNodeId(postBody) {
    let url = `${this.config.getApi('globalDocumentsAPI')}${this.getDataByNicknameAPI
      }`;

    return this.http.post<any>(url, postBody);
  }

  getWebReportForAddTenantToProperty(postBody): Observable<string> {
    const url = `${this.config.getApi('globalDocumentsAPI')}${this.getWebReportForAddTenantToPropertyAPI
      }`;
    return this.http.post<string>(url, postBody, httpOptions);
  }

  updateCategoriesByNodeId(postBody): Observable<string> {
    const url = `${this.config.getApi('documentsAPI')}${this.UpdateCategoriesByNodeId
      }`;
    return this.http.post<string>(url, postBody);
  }
  updateCategoriesByNicknames(postBody): Observable<string> {
    const url = `${this.config.getApi('globalDocumentsAPI')}${this.UpdateCategoriesByNicknames
      }`;
    return this.http.post<string>(url, postBody, httpOptions);
  }

  getPropNickName(property) {
    return `AV_${property.id}`;
  }

  getRootFolderNickName() {
    return this.config.get('rootdocNickname'); // `AV_PropertyManagement`;
  }
}
