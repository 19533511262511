import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[trackScroll]',
})
export class TrackScrollDirective {

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    console.log('Scroll Event', window.pageYOffset, event );
  }

}
