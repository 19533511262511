import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService, CommonServices } from '@pulse/common';

@Injectable({
  providedIn: 'root'
})
export class DeletePropertyGroupService {
  constructor(private http: HttpClient, private appConfig: AppConfigService,  private dateUtilService: CommonServices) { }

  deleteGrouptUrl = 'property-group/Delete/';

  deleteGroup(groupId: Number): Observable<number> {
    let config = {
      headers: {
        timezone: this.dateUtilService.getTimeZones(),
      },
    };
    return this.http.delete<number>(
      this.appConfig.getApi('propertyGroupAPI') + this.deleteGrouptUrl + groupId, config
    );
  }
}
